import { FC } from 'react'
import { AutoComplete as PrimeAutoComplete } from 'primereact/autocomplete'
import { ErrorMessage } from './ErrorMessage'

interface AutoCompleteProps {
  field?: string
  tooltip?: string
  tooltipOptions?: { position: string }
  placeholder: string
  value: string
  columnSize?: string
  handleCompleteMethod: (e: { originalEvent: Event; query: string }) => void
  suggestions: any[] | undefined
  handleChange: (e: {
    originalEvent: Event
    value: any
    name?: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => void
  dropdown?: boolean
  icon?: JSX.Element
  handleSelect?: (e: { originalEvent: Event; value: any }) => void
  errorMsg?: string
}

const AutoComplete: FC<AutoCompleteProps> = (props) => {
  const { tooltipOptions = { position: 'top' }, dropdown = true } = props
  return (
    <div
      className={props.columnSize ? props.columnSize : 'p-col-12'}
    >
      <div style={{ display: 'flex' }}>
      <div
        style={{
          background: '#504E53',
          width: '54.38px',
          height: '45px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {props.icon}
        </div>
      </div>
      <PrimeAutoComplete
        field={props.field}
        dropdown={dropdown}
        tooltip={props.tooltip}
        tooltipOptions={tooltipOptions}
        placeholder={props.placeholder}
        value={props.value}
        completeMethod={props.handleCompleteMethod}
        suggestions={props.suggestions}
        onChange={props.handleChange}
        onSelect={props.handleSelect}
        />
        </div>
      <div style={{ marginLeft: '.6rem', marginTop: '.2rem' }}>
        {props.errorMsg && <ErrorMessage message={props.errorMsg} />}
      </div>
    </div>
  )
}

export { AutoComplete }
