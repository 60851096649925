import styled from 'styled-components';

export const Address = styled.div`
  padding: 0.8rem;
  font-size: .9rem;
  text-align: center;
  position: absolute;
  top: 98%;
  background: #fff;
  display: block;
  width: 50rem;
  letter-spacing: 1.5px;
  border-bottom: 1px solid black;
  padding-bottom: 0.3rem;
`;
