import styled from 'styled-components';

interface BoxProps {
  removeBorder?: boolean;
  decreasePadding?: boolean;
  decreaseMargin?: boolean;
}

export const ThankYou = styled.div<BoxProps>`
  margin-top: ${(props) => (!props.decreasePadding ? '0.8rem 0' : '0.2rem 0')};
  display: block;
  padding: ${(props) => (!props.decreasePadding ? '0.8rem 0' : '0.2rem 0')};
  text-align: center;
  text-transform: uppercase;
  border-top: ${(props) => (!props.removeBorder ? '1px solid #000' : 'none')};
  border-bottom: ${(props) => (!props.removeBorder ? '1px solid #000' : 'none')};
`;
