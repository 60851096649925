import { FC, useRef, useState, MouseEvent, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import {
  AvaiableStockDocument,
  GetProductVariationListDocument,
  ProductVariationResponseType,
  useDeleteProductVarianceMutation,
  useGetBrandsQuery,
  useGetProductVariationListQuery,
  useGetUniqueProductCodesQuery,
  useGetUniqueProductColorsQuery,
  useGetUniqueProductSizesQuery,
} from '../../generated/graphql'
import { Spinner } from '../../components'
import { currencyFormat } from '../../helpers/currencyFormat'
import { AddSubProduct } from '../../components/inventory'
import { Toast } from 'primereact/toast'
import { Dialog } from '../../components'
import { confirmPopup } from 'primereact/confirmpopup'
import { AutoComplete, Dropdown } from '../../components/form'
import { BiSearchAlt } from 'react-icons/bi'
import { arrayUnique } from '../../helpers/arrayUnique'
import { AiOutlineNumber } from 'react-icons/ai'
import { CgSize } from 'react-icons/cg'
import { DataTableWrapper } from '../../components/DataTableWrapper'

const ProductVariationList: FC = () => {
  const limitOptions = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
  ]
  const [brand, setBrand] = useState('');
  const [code, setCode] = useState('');
  const [limit, setLimit] = useState('');
  const [cursor, setCursor] = useState('');
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const { data: codes } = useGetUniqueProductCodesQuery();
  const [filteredBrands, setFilteredBrands] = useState<string[]>();
  const [filteredCodes, setFilteredCodes] = useState<string[]>();
  const [filteredColors, setFilteredColors] = useState<string[]>();
  const [filteredSizes, setFilteredsizes] = useState<string[]>();
  const [addSubProduct, setAddSubProduct] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { data: sizes } = useGetUniqueProductSizesQuery();
  const { data: colors } = useGetUniqueProductColorsQuery();
  const { data: brands } = useGetBrandsQuery();
  const { loading, data, client, fetchMore } = useGetProductVariationListQuery({
    variables: {
      input: {
        limit,
        brand,
        code,
        size,
        color
      },
    },
    notifyOnNetworkStatusChange: true,
  })
  const [show, setShow] = useState(false)
  const [subProduct, setSubProduct] = useState<ProductVariationResponseType>()
  const toastSuccess = useRef<Toast>(null)
  const toastError = useRef<Toast>(null)
  const [deleteProductVariaiton] = useDeleteProductVarianceMutation()

  useEffect(() => {
    setHasMore(data?.getProductVariationList.hasMore!)
    if (data?.getProductVariationList.hasMore) {
      const productVariations =
        data?.getProductVariationList.productVariaitons || []
      if (productVariations.length > 0) {
        setCursor(productVariations[productVariations.length - 1].id.toString())
      }
    }
  }, [loading])

  const handleOnClickAction = (
    e: MouseEvent<HTMLButtonElement>,
    action: string,
    data: ProductVariationResponseType,
  ) => {
    if (action === 'edit') {
      setSubProduct(data)
      setShow(true)
    }
    if (action === 'delete') {
      confirmPopup({
        target: e.currentTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteProductVariaiton({
            variables: { input: data.id! },
            refetchQueries: [
              {
                query: GetProductVariationListDocument,
                variables: {
                  input: {},
                },
              },
              {
                query: AvaiableStockDocument,
                variables: { input: {} },
              },
            ],
            update: (cache, { data: response }) => {
              if (response?.deleteProductVariance.message) {
                const id = cache.identify(data)
                cache.evict({ id })
              }
            },
          })
          if (response.data?.deleteProductVariance.message) {
            showSuccess(response.data?.deleteProductVariance.message)
          } else {
            showError(response.data?.deleteProductVariance.errors![0].message!)
          }
        },
        reject: () => false,
      })
    }
  }

  const handleLimit = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setLimit(e.value)
    client.resetStore()
  }

  const handleSize = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setSize(e.value)
    client.resetStore()
  }

  const handleColor = (e: {
    originalEvent: Event
    value: any
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setColor(e.value)
    client.resetStore()
  }

  const handleLoadMore = async () => {
    fetchMore({
      variables: {
        input: {
          limit,
          cursor,
          brand,
          code,
          size,
          color
        },
      },
    })
  }

  const clearFilters = () => {
    setLimit('');
    setBrand('');
    setCode('');
    setSize('');
    setColor('');
    client.resetStore();
  }

  useEffect(() => {
    clearFilters()
  }, [])

  const showSuccess = (message: string) => {
    if (toastSuccess && toastSuccess.current) {
      toastSuccess.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,
        life: 5000,
      })
    }
  }

  const showError = (message: string) => {
    if (toastError && toastError.current) {
      toastError.current.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: 5000,
      })
    }
  }

  const searchBrand = (e: { originalEvent: Event; query: string }) => {
    let filteredBrands: string[]
    filteredBrands =
      brands?.getBrands.map(b => b.name).filter((b) => {
        return b.toLowerCase().includes(e.query.toLowerCase())
      }) || [];
    setFilteredBrands(arrayUnique(filteredBrands))
  }

  const searchCode = (e: { originalEvent: Event; query: string }) => {
    let filteredCodes: string[]
    filteredCodes =
      codes?.getUniqueProductCodes.filter((p) => {
        return p.toLowerCase().startsWith(e.query.toLowerCase())
      }) || []
    setFilteredCodes(arrayUnique(filteredCodes))
  }

  const searchColor = (e: { originalEvent: Event; query: string }) => {
    let filteredColors: string[]
    filteredColors =
      colors?.getUniqueProductColors.filter((p) => {
        return p.toLowerCase().startsWith(e.query.toLowerCase())
      }) || []
      setFilteredColors(arrayUnique(filteredColors))
  }

  const searchSize = (e: { originalEvent: Event; query: string }) => {
    let filteredSizes = sizes?.getUniqueProductSizes ? [...sizes?.getUniqueProductSizes] : [];
    filteredSizes =
    filteredSizes.sort((a, b) => parseFloat(a) - parseFloat(b)).filter((c) => {
        return c.toLowerCase().includes(e.query.toLowerCase())
      }) || []
    setFilteredsizes(arrayUnique(filteredSizes))
  }

  const handleAutoCompleteBrand = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setBrand(e.value)
  }

  const handleAutoCompleteColor = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setColor(e.value)
  }

  const handleAutoCompleteSize = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setSize(e.value)
  }

  const handleAutoCompleteCode = (e: {
    originalEvent: Event
    value: string
    target: {
      name: string
      id: string
      value: any
    }
  }) => {
    setCode(e.value)
  }

  const handleAutoCompleteSelect = () => {
    client.resetStore()
  }

  const tableHeader = (
    <div className="p-grid">
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Brand"
        value={brand}
        handleCompleteMethod={searchBrand}
        suggestions={filteredBrands}
        handleChange={handleAutoCompleteBrand}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Code"
        value={code}
        handleCompleteMethod={searchCode}
        suggestions={filteredCodes}
        handleChange={handleAutoCompleteCode}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search Color"
        value={color}
        handleCompleteMethod={searchColor}
        suggestions={filteredColors}
        handleChange={handleAutoCompleteColor}
        handleSelect={handleAutoCompleteSelect}
      />
      <AutoComplete
        columnSize="p-col-3"
        icon={<BiSearchAlt color="#02c0f8" />}
        placeholder="Search size"
        value={size}
        handleCompleteMethod={searchSize}
        suggestions={filteredSizes}
        handleChange={handleAutoCompleteSize}
        handleSelect={handleAutoCompleteSelect}
      />
      {/* <Dropdown
        columnSize="p-col-3"
        icon={<CgSize color="#01c0f8" />}
        placeholder="Pick a color"
        value={color}
        handleChange={handleColor}
        name="color"
        options={colors?.getUniqueProductColors.map((s) => ({
          label: s.toString(),
          value: s.toString(),
        }))}
        optionLabel="label"
        optionValue="value"
      /> */}
      {/* <Dropdown
        columnSize="p-col-3"
        icon={<CgSize color="#01c0f8" />}
        placeholder="Pick a size"
        value={size}
        handleChange={handleSize}
        name="size"
        options={sizes?.getUniqueProductSizes.map((s) => ({
          label: s.toString(),
          value: s.toString(),
        }))}
        optionLabel="label"
        optionValue="value"
      /> */}
      <Dropdown
        columnSize="p-col-3"
        icon={<AiOutlineNumber color="#01c0f8" />}
        placeholder="Record Limit"
        value={limit}
        handleChange={handleLimit}
        name="limit"
        options={limitOptions}
        optionLabel="label"
        optionValue="value"
      />
    </div>
  )
  return loading ? (
    <Spinner />
  ) : (
    <>
      <Toast ref={toastSuccess} />
      <Toast ref={toastError} />
        <Dialog
          header="Update Sub Product"
          visible={show}
          handleOnHide={() => setShow(false)}
        >
        <AddSubProduct
          hideDialog={() => setShow(false)}
          toastSuccess={showSuccess}
          toastError={showError}
          subProduct={subProduct}
          edit
        />
      </Dialog>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <Button
          onClick={() => setAddSubProduct(true)}
          className="p-mb-3 p-button-sm"
          type="button"
        >
          <i className="pi pi-plus p-mr-2" /> New
        </Button>
      </div>
      <Dialog
        header="Add Sub Product"
        visible={addSubProduct}
        handleOnHide={() => setAddSubProduct(false)}
      >
        <AddSubProduct
          hideDialog={() => setAddSubProduct(false)}
          toastSuccess={showSuccess}
          toastError={showError}
        />
      </Dialog>
      {loading ? (
        <Spinner />
      ) : (
        <DataTableWrapper>
          <DataTable
            header={tableHeader}
            value={data?.getProductVariationList.productVariaitons}
            emptyMessage="No Products"
            className="p-datatable-responsive"
          >
            <Column
              header="Id"
              body={(_: any, props: any) => props.rowIndex + 1}
            ></Column>
            <Column field="code" header="Code" sortable></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column field="color" header="Color"></Column>
            <Column field="size" header="Size" sortable></Column>
            <Column
              field="price"
              header="Price"
              sortable
              body={(data: ProductVariationResponseType) =>
                currencyFormat(data.price)
              }
            ></Column>
            <Column
              header="Action"
              body={(data: ProductVariationResponseType) => (
                <>
                  <Button
                    icon="pi pi-pencil"
                    tooltip={`Edit ${data.name}`}
                    tooltipOptions={{ position: 'bottom' }}
                    className="p-button-text p-button-sm"
                    onClick={(e: any) => handleOnClickAction(e, 'edit', data)}
                    type="button"
                  />
                  <Button
                    icon="pi pi-trash"
                    tooltip={`Delete ${data.name}`}
                    tooltipOptions={{ position: 'bottom' }}
                    className="p-button-text p-button-sm"
                    style={{ marginLeft: '1rem' }}
                    onClick={(e: any) => handleOnClickAction(e, 'delete', data)}
                    type="button"
                  />
                </>
              )}
              headerStyle={{ width: '8em', textAlign: 'center' }}
              bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            />
          </DataTable>
        </DataTableWrapper>
      )}
      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '2rem',
        }}
      >
        <div className="p-d-flex p-jc-between">
          <Button onClick={clearFilters} type="button" className="p-button-sm">
            {loading ? 'Loading' : 'Clear Filters'}
          </Button>
          <Button
            onClick={handleLoadMore}
            disabled={!hasMore}
            className="p-button-sm"
          >
            {loading ? 'Loading' : 'Load More'}
          </Button>
        </div>
      </div>
    </>
  )
}

export { ProductVariationList }
