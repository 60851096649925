import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Query = {
  __typename?: 'Query';
  getOrderNumber: Scalars['Int'];
  getReturnReceiptNumber: Scalars['Int'];
  getReturnedItems: Array<ReturnItem>;
  getExchangeReceipts: Array<Return>;
  getExchangeReceiptInfo?: Maybe<ExchangeReceiptInfoResponse>;
  getReturnedOrder?: Maybe<GetReturnOrderResponse>;
  getOrderList: OrderListResponse;
  permissions: Array<Permission>;
  roles: Array<Role>;
  getAvaiableStock: StockAvailableResponse;
  getStockValue: StockValueResponse;
  getStockVariance: StockVarianceResponse;
  getReturnableStocks: Array<ReturnableStocksResponse>;
  getStockInvoices: StockInvoiceResponse;
  getUpdateStockQuantity: UpdateStockQuantityResponse;
  getUsers: Array<User>;
  getUser: UserResponse;
  me: UserResponse;
  sendPing: Scalars['Boolean'];
  getSupplierNames: Array<SupplierName>;
  getSupplierList: Array<Supplier>;
  getPaymentTypes: Array<StockPaymentType>;
  getOutstanding: Array<SupplierOutstanding>;
  purchaseHistory: Array<PurchaseHistoryResponse>;
  paymentHistory: Array<SupplierPayment>;
  getSupplierReturn: Array<SupplierReturnResponse>;
  stockSettlement: Array<StockSettlement>;
  chequePayments: ChequePaymentResponse;
  getBrands: Array<Product>;
  getProductTypes: ProductVariationTypeResponse;
  getProducts: Array<ProductResponse>;
  searchProductTypes: Array<ProductVariationType>;
  getProductsForReceipt: Array<ProductListResponse>;
  getProductVariationList: ProductVariationResponse;
  getProductsList: Array<Product>;
  getUniqueProductSizes: Array<Scalars['String']>;
  getUniqueProductCountries: Array<Scalars['String']>;
  getUniqueProductColors: Array<Scalars['String']>;
  getUniqueProductCodes: Array<Scalars['String']>;
  getUniqueProductNames: Array<Scalars['String']>;
  getCustomers: CustomerListResponse;
  categories: Array<Category>;
  getBanks: Array<Bank>;
  orderPaymentTypes: Array<OrderPaymentType>;
  getSales: SalesResponse;
  getLastSevenDaysSales: LastSevenDaySalesResponse;
  getTestSalesReport: TestSalesResponse;
  getSalesAchieved: SalesTargetResponse;
  getBranches: Array<Branch>;
};


export type QueryGetReturnedItemsArgs = {
  input: Scalars['Float'];
};


export type QueryGetExchangeReceiptInfoArgs = {
  input: Scalars['Float'];
};


export type QueryGetReturnedOrderArgs = {
  input: Scalars['Float'];
};


export type QueryGetOrderListArgs = {
  input: OrderListInput;
};


export type QueryGetAvaiableStockArgs = {
  input?: Maybe<StockAvailableInput>;
};


export type QueryGetStockValueArgs = {
  input?: Maybe<StockValueInput>;
};


export type QueryGetStockVarianceArgs = {
  input?: Maybe<StockVarianceInput>;
};


export type QueryGetReturnableStocksArgs = {
  input: Scalars['Float'];
};


export type QueryGetStockInvoicesArgs = {
  input?: Maybe<StockInvoiceInput>;
};


export type QueryGetUpdateStockQuantityArgs = {
  input: UpdateStockQuantityInput;
};


export type QueryGetUserArgs = {
  id: Scalars['Float'];
};


export type QueryPurchaseHistoryArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  input: PurchaseHistoryInput;
};


export type QueryPaymentHistoryArgs = {
  input: PaymentHistoryInput;
};


export type QueryGetSupplierReturnArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  input: GetSupplierReturnInput;
};


export type QueryStockSettlementArgs = {
  id: Scalars['Float'];
};


export type QueryChequePaymentsArgs = {
  input: ChequePaymentInput;
};


export type QueryGetProductTypesArgs = {
  input?: Maybe<ProductVariationTypesInput>;
};


export type QuerySearchProductTypesArgs = {
  input: Scalars['Float'];
};


export type QueryGetProductsForReceiptArgs = {
  input: Scalars['String'];
};


export type QueryGetProductVariationListArgs = {
  input?: Maybe<ProductVariationListInput>;
};


export type QueryGetSalesArgs = {
  input: GetSalesInput;
};


export type QueryGetLastSevenDaysSalesArgs = {
  input: GetSalesInput;
};


export type QueryGetTestSalesReportArgs = {
  input: GetSalesInput;
};


export type QueryGetSalesAchievedArgs = {
  input: GetSalesTargetInput;
};

export type ReturnItem = {
  __typename?: 'ReturnItem';
  id: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type Return = {
  __typename?: 'Return';
  id: Scalars['Int'];
  return_receipt_number: Scalars['Int'];
  associated: Order;
  date: Scalars['String'];
  branch: Branch;
  productVariationToReturn: Array<ProductVariationToReturn>;
  exchangeOrder?: Maybe<ExchangeOrder>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['Float'];
  order_number: Scalars['Int'];
  date: Scalars['String'];
  void: Scalars['Boolean'];
  user: Scalars['Int'];
  orderToProductVariation: Array<OrderToProductVariation>;
  customer?: Maybe<Scalars['Int']>;
  returns: Return;
  branch: Branch;
  orderPayment: OrderPayment;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
};

export type OrderToProductVariation = {
  __typename?: 'OrderToProductVariation';
  id: Scalars['Float'];
  quantity: Scalars['Float'];
  price: Scalars['Float'];
  discount?: Maybe<Scalars['Float']>;
  discountType?: Maybe<DiscountType>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type DiscountType = {
  __typename?: 'DiscountType';
  id: Scalars['Int'];
  name: Scalars['String'];
  orderToVariations: Array<OrderToProductVariation>;
  productReturns: Array<ProductVariationToReturn>;
};

export type ProductVariationToReturn = {
  __typename?: 'ProductVariationToReturn';
  id: Scalars['Int'];
  productVariation: ProductVariation;
  stockToVariation?: Maybe<StockToVariation>;
  returns: Return;
  quantity: Scalars['Float'];
  price: Scalars['Float'];
  discount: Scalars['Float'];
  discountType: DiscountType;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type ProductVariation = {
  __typename?: 'ProductVariation';
  id: Scalars['Float'];
  barcode?: Maybe<Scalars['String']>;
  category: Category;
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  price_ogf: Scalars['Float'];
  price_ogff: Scalars['Float'];
  price_bg: Scalars['Float'];
  price_hc: Scalars['Float'];
  ongoing: Ongoing;
  product: Product;
  productVariationToReturn: Array<ProductVariationToReturn>;
  productVariationType: ProductVariationType;
  stockToVariations: Array<StockToVariation>;
  orderToProductVariation: Array<OrderToProductVariation>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Category>;
  children?: Maybe<Category>;
  products: Array<Product>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
  subCategories?: Maybe<Array<Category>>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['Float'];
  name: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  productVariationType: Array<ProductVariationType>;
  categories?: Maybe<Array<Category>>;
  productVariations: Array<ProductVariation>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type ProductVariationType = {
  __typename?: 'ProductVariationType';
  id: Scalars['Float'];
  name: Scalars['String'];
  code: Scalars['String'];
  size: Scalars['String'];
  product: Product;
  productVariations: Array<ProductVariation>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type Ongoing = {
  __typename?: 'Ongoing';
  id: Scalars['Int'];
  quantity: Scalars['Float'];
  stockToVariation: StockToVariation;
  productVariation: ProductVariation;
};

export type StockToVariation = {
  __typename?: 'StockToVariation';
  id: Scalars['Float'];
  quantity: Scalars['Float'];
  remaining: Scalars['Float'];
  purchase_price: Scalars['Float'];
  ongoing: Ongoing;
  supplierReturns: Array<SupplierReturn>;
  productVariationToReturn: Array<ProductVariationToReturn>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type SupplierReturn = {
  __typename?: 'SupplierReturn';
  id: Scalars['Int'];
  supplier: Supplier;
  stockVariation: StockToVariation;
  return_quantity: Scalars['Int'];
  return_date: Scalars['String'];
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['ID'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  creditPeriod?: Maybe<Scalars['Int']>;
  stocks?: Maybe<Array<Stock>>;
  supplierAdvance?: Maybe<SupplierAdvance>;
  supplierContacts?: Maybe<Array<SupplierContact>>;
  supplierReturns?: Maybe<Array<SupplierReturn>>;
  supplierPayments?: Maybe<Array<SupplierPayment>>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type Stock = {
  __typename?: 'Stock';
  id: Scalars['Float'];
  date: Scalars['String'];
  creditPeriod?: Maybe<Scalars['Int']>;
  branch: Branch;
  settled_date?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type Branch = {
  __typename?: 'Branch';
  id: Scalars['Float'];
  name: Scalars['String'];
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type SupplierAdvance = {
  __typename?: 'SupplierAdvance';
  id: Scalars['Float'];
  amount: Scalars['Float'];
};

export type SupplierContact = {
  __typename?: 'SupplierContact';
  id: Scalars['Float'];
  contact: Scalars['String'];
};

export type SupplierPayment = {
  __typename?: 'SupplierPayment';
  id: Scalars['Int'];
  date: Scalars['String'];
  amount: Scalars['Float'];
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  stockPaymentType: StockPaymentType;
  branch?: Maybe<Branch>;
  paymentInfo?: Maybe<SupplierPaymentInfo>;
};

export type StockPaymentType = {
  __typename?: 'StockPaymentType';
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type SupplierPaymentInfo = {
  __typename?: 'SupplierPaymentInfo';
  id: Scalars['Float'];
  cheque_number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  cheque_date?: Maybe<Scalars['String']>;
  bank?: Maybe<Bank>;
  supplierPayment?: Maybe<SupplierPayment>;
};

export type Bank = {
  __typename?: 'Bank';
  id: Scalars['Int'];
  name: Scalars['String'];
  stockPaymentInfo?: Maybe<Array<SupplierPaymentInfo>>;
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  id: Scalars['Float'];
  amount_paid: Scalars['Float'];
  points?: Maybe<Scalars['Float']>;
};

export type ExchangeOrder = {
  __typename?: 'ExchangeOrder';
  id: Scalars['Int'];
  order: Order;
  returns: Return;
};

export type ExchangeReceiptInfoResponse = {
  __typename?: 'ExchangeReceiptInfoResponse';
  returnId: Scalars['Float'];
  storeCredit: Scalars['Float'];
  orderNumber: Scalars['Int'];
  items?: Maybe<Array<Item>>;
  date: Scalars['String'];
  branch: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['Int'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  discount?: Maybe<Scalars['Float']>;
  discountType?: Maybe<Scalars['Int']>;
  addToStock: Scalars['Int'];
  supplier?: Maybe<Supplier>;
  productVariationId?: Maybe<Scalars['Int']>;
  stockVariationId?: Maybe<Scalars['Int']>;
  stockDate?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type GetReturnOrderResponse = {
  __typename?: 'GetReturnOrderResponse';
  order?: Maybe<Order>;
  points?: Maybe<Scalars['Float']>;
  user?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Item>>;
  customer?: Maybe<Customer>;
  returnedOrderNumber: Scalars['Int'];
  returnedReceiptAmount: Scalars['Float'];
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  orderPayment?: Maybe<OrderPayment>;
  hcmLoyality?: Maybe<Scalars['Float']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['Float'];
  title: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone: Scalars['String'];
  points: Scalars['Float'];
  joined: Scalars['String'];
  nic?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  branch: Branch;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  amount: Scalars['Float'];
  type: Scalars['String'];
};

export type OrderListResponse = {
  __typename?: 'OrderListResponse';
  orderList: Array<OrderList>;
  hasMore: Scalars['Boolean'];
};

export type OrderList = {
  __typename?: 'OrderList';
  id: Scalars['Int'];
  orderNumber: Scalars['Int'];
  date: Scalars['String'];
  void: Scalars['Boolean'];
  cashier: Scalars['String'];
  total: Scalars['Float'];
};

export type OrderListInput = {
  limit?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  date_filter?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['Int'];
  name: Scalars['String'];
  parent: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Permission>>;
};

export type StockAvailableResponse = {
  __typename?: 'StockAvailableResponse';
  bgAvailable?: Maybe<Array<BgStockAvailable>>;
  ogfAvailable?: Maybe<Array<OgfStockAvailable>>;
  ogffAvailable?: Maybe<Array<OgffStockAvailable>>;
  hcAvailable?: Maybe<Array<HcStockAvailable>>;
  hasMore: Scalars['Boolean'];
};

export type BgStockAvailable = {
  __typename?: 'BgStockAvailable';
  id: Scalars['Int'];
  pv_id: Scalars['Int'];
  category: Scalars['String'];
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  product_size: Scalars['Int'];
  available: Scalars['Float'];
  in_stock: Scalars['Boolean'];
};

export type OgfStockAvailable = {
  __typename?: 'OgfStockAvailable';
  id: Scalars['Int'];
  pv_id: Scalars['Int'];
  category: Scalars['String'];
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  product_size: Scalars['Int'];
  available: Scalars['Float'];
  in_stock: Scalars['Boolean'];
};

export type OgffStockAvailable = {
  __typename?: 'OgffStockAvailable';
  id: Scalars['Int'];
  pv_id: Scalars['Int'];
  category: Scalars['String'];
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  product_size: Scalars['Int'];
  available: Scalars['Float'];
  in_stock: Scalars['Boolean'];
};

export type HcStockAvailable = {
  __typename?: 'HcStockAvailable';
  id: Scalars['Int'];
  pv_id: Scalars['Int'];
  category: Scalars['String'];
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  product_size: Scalars['Int'];
  available: Scalars['Float'];
  in_stock: Scalars['Boolean'];
};

export type StockAvailableInput = {
  limit?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type StockValueResponse = {
  __typename?: 'StockValueResponse';
  bgValue?: Maybe<Array<BgStockValue>>;
  ogfValue?: Maybe<Array<OgfStockValue>>;
  ogffValue?: Maybe<Array<OgffStockValue>>;
  hcValue?: Maybe<Array<HcStockValue>>;
  quantity: Scalars['Float'];
  total: Scalars['Float'];
  hasMore: Scalars['Boolean'];
};

export type BgStockValue = {
  __typename?: 'BgStockValue';
  id: Scalars['Int'];
  country: Scalars['String'];
  supplier: Scalars['String'];
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  quantity: Scalars['Float'];
  purchase_price: Scalars['Float'];
  total: Scalars['Float'];
};

export type OgfStockValue = {
  __typename?: 'OgfStockValue';
  id: Scalars['Int'];
  country: Scalars['String'];
  supplier: Scalars['String'];
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  quantity: Scalars['Float'];
  purchase_price: Scalars['Float'];
  total: Scalars['Float'];
};

export type OgffStockValue = {
  __typename?: 'OgffStockValue';
  id: Scalars['Int'];
  country: Scalars['String'];
  supplier: Scalars['String'];
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  quantity: Scalars['Float'];
  purchase_price: Scalars['Float'];
  total: Scalars['Float'];
};

export type HcStockValue = {
  __typename?: 'HcStockValue';
  id: Scalars['Int'];
  country: Scalars['String'];
  supplier: Scalars['String'];
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  quantity: Scalars['Float'];
  purchase_price: Scalars['Float'];
  total: Scalars['Float'];
};

export type StockValueInput = {
  limit?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type StockVarianceResponse = {
  __typename?: 'StockVarianceResponse';
  stockVariance: Array<StockVariance>;
  hasMore: Scalars['Boolean'];
};

export type StockVariance = {
  __typename?: 'StockVariance';
  id: Scalars['Int'];
  code: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  size: Scalars['String'];
  spv_id: Scalars['Int'];
  quantity: Scalars['Float'];
  purchase_price: Scalars['Float'];
  total: Scalars['Float'];
  branch: Scalars['String'];
  date: Scalars['String'];
  supplier_id: Scalars['Float'];
  supplier_name: Scalars['String'];
};

export type StockVarianceInput = {
  limit?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  date_filter?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type ReturnableStocksResponse = {
  __typename?: 'ReturnableStocksResponse';
  stock_id: Scalars['Int'];
  date: Scalars['String'];
  product_variation_id: Scalars['Int'];
  name: Scalars['String'];
  returnable: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type StockInvoiceResponse = {
  __typename?: 'StockInvoiceResponse';
  bgInvoice?: Maybe<Array<BgInvoice>>;
  ogfInvoice?: Maybe<Array<OgfInvoice>>;
  ogffInvoice?: Maybe<Array<OgffInvoice>>;
  hcInvoice?: Maybe<Array<HcmInvoice>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type BgInvoice = {
  __typename?: 'BGInvoice';
  id: Scalars['Int'];
  stock_id: Scalars['Int'];
  date: Scalars['String'];
  supplier: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type OgfInvoice = {
  __typename?: 'OGFInvoice';
  id: Scalars['Int'];
  stock_id: Scalars['Int'];
  date: Scalars['String'];
  supplier: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type OgffInvoice = {
  __typename?: 'OGFFInvoice';
  id: Scalars['Int'];
  stock_id: Scalars['Int'];
  date: Scalars['String'];
  supplier: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type HcmInvoice = {
  __typename?: 'HCMInvoice';
  id: Scalars['Int'];
  stock_id: Scalars['Int'];
  date: Scalars['String'];
  supplier: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type StockInvoiceInput = {
  limit?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  date_filter?: Maybe<Scalars['String']>;
};

export type UpdateStockQuantityResponse = {
  __typename?: 'UpdateStockQuantityResponse';
  stock?: Maybe<Array<UpdateStockQuantity>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type UpdateStockQuantity = {
  __typename?: 'UpdateStockQuantity';
  id: Scalars['Float'];
  name: Scalars['String'];
  brand: Scalars['String'];
  category: Scalars['String'];
  code: Scalars['String'];
  quantity: Scalars['Int'];
  pv_id: Scalars['Int'];
  spv_id: Scalars['Int'];
  supplier: Scalars['String'];
  date: Scalars['String'];
  user: User;
  branch: Branch;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  approved: Scalars['Boolean'];
  branch: Branch;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
  role: Role;
};

export type UpdateStockQuantityInput = {
  limit?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  date_filter?: Maybe<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  user?: Maybe<User>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  message: Scalars['String'];
  field?: Maybe<Scalars['String']>;
};

export type SupplierName = {
  __typename?: 'SupplierName';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SupplierOutstanding = {
  __typename?: 'SupplierOutstanding';
  id: Scalars['Int'];
  name: Scalars['String'];
  address: Scalars['String'];
  outstanding: Scalars['Float'];
  last_settled_date?: Maybe<Scalars['String']>;
  days: Scalars['Int'];
  branch_id?: Maybe<Scalars['Int']>;
};

export type PurchaseHistoryResponse = {
  __typename?: 'PurchaseHistoryResponse';
  id: Scalars['Int'];
  date: Scalars['String'];
  total: Scalars['Float'];
  days: Scalars['Int'];
};

export type PurchaseHistoryInput = {
  supplierId: Scalars['Int'];
  branchId: Scalars['Int'];
};

export type PaymentHistoryInput = {
  supplierId: Scalars['Int'];
};

export type SupplierReturnResponse = {
  __typename?: 'SupplierReturnResponse';
  id: Scalars['Int'];
  name: Scalars['String'];
  quantity: Scalars['Float'];
  date: Scalars['String'];
  purchase_price: Scalars['Float'];
};

export type GetSupplierReturnInput = {
  supplierId: Scalars['Int'];
  branchId: Scalars['Int'];
};

export type StockSettlement = {
  __typename?: 'StockSettlement';
  id: Scalars['Int'];
  stock_id: Scalars['Int'];
  stock_date: Scalars['String'];
  creditPeriod: Scalars['Int'];
  supplier_id: Scalars['Int'];
  branch_id: Scalars['Int'];
  payable: Scalars['Float'];
  paid?: Maybe<Scalars['Float']>;
  payment_date?: Maybe<Scalars['String']>;
  is_settled: Scalars['Boolean'];
  settled_date?: Maybe<Scalars['String']>;
};

export type ChequePaymentResponse = {
  __typename?: 'ChequePaymentResponse';
  chequePayments: Array<ChequePayment>;
  hasMore: Scalars['Boolean'];
  totalAmount: Scalars['Float'];
};

export type ChequePayment = {
  __typename?: 'ChequePayment';
  id: Scalars['Int'];
  branchId: Scalars['Int'];
  supplierId: Scalars['Int'];
  amount: Scalars['Float'];
  paymentDate: Scalars['String'];
  chequeDate: Scalars['String'];
  status: Scalars['String'];
};

export type ChequePaymentInput = {
  limit?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  stat?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  date_filter?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
};

export type ProductVariationTypeResponse = {
  __typename?: 'ProductVariationTypeResponse';
  hasMore: Scalars['Boolean'];
  productVariationTypes: Array<ProductVariationType>;
};

export type ProductVariationTypesInput = {
  limit?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ProductResponse = {
  __typename?: 'ProductResponse';
  id: Scalars['Int'];
  brand: Scalars['String'];
  country: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  measurement: Scalars['String'];
  price: Scalars['Float'];
};

export type ProductListResponse = {
  __typename?: 'ProductListResponse';
  id: Scalars['Int'];
  barcode?: Maybe<Scalars['String']>;
  discount: Scalars['Float'];
  discount_type: Scalars['Int'];
  product_size: Scalars['String'];
  size: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  code: Scalars['String'];
  quantity: Scalars['Float'];
  price: Scalars['Float'];
  sub_total: Scalars['Float'];
  available?: Maybe<Scalars['Float']>;
  in_stock?: Maybe<Scalars['Boolean']>;
};

export type ProductVariationResponse = {
  __typename?: 'ProductVariationResponse';
  productVariaitons: Array<ProductVariationResponseType>;
  hasMore: Scalars['Boolean'];
};

export type ProductVariationResponseType = {
  __typename?: 'ProductVariationResponseType';
  id: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
  size: Scalars['String'];
  color: Scalars['String'];
  price: Scalars['Float'];
  type_id: Scalars['Int'];
  product_id: Scalars['Int'];
  category_id: Scalars['Int'];
};

export type ProductVariationListInput = {
  limit?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CustomerListResponse = {
  __typename?: 'CustomerListResponse';
  customers?: Maybe<Array<Customer>>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type OrderPaymentType = {
  __typename?: 'OrderPaymentType';
  id: Scalars['Float'];
  name: Scalars['String'];
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type SalesResponse = {
  __typename?: 'SalesResponse';
  sales?: Maybe<Array<SalesReport>>;
  hasMore?: Maybe<Scalars['Boolean']>;
  totalSale?: Maybe<Scalars['Float']>;
  totalProfit?: Maybe<Scalars['Float']>;
  itemsSold?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type SalesReport = {
  __typename?: 'SalesReport';
  id: Scalars['Int'];
  orderNumber: Scalars['Int'];
  brandName: Scalars['String'];
  country: Scalars['String'];
  productName: Scalars['String'];
  code: Scalars['String'];
  color: Scalars['String'];
  size: Scalars['String'];
  date: Scalars['String'];
  date_time: Scalars['String'];
  time: Scalars['String'];
  branchId: Scalars['Int'];
  supplierId: Scalars['Int'];
  productVariationId: Scalars['Int'];
  stockProductVariationId: Scalars['Int'];
  purchasePrice: Scalars['Float'];
  sellingPrice: Scalars['Float'];
  discount: Scalars['Float'];
  totalQuantity: Scalars['Float'];
  returnQuantity: Scalars['Float'];
  paymentMethod?: Maybe<Scalars['JSON']>;
  isReturn: Scalars['Boolean'];
  associatedOrderNumber?: Maybe<Scalars['Int']>;
  return?: Maybe<Scalars['Float']>;
};


export type GetSalesInput = {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  date_filter?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  itemsSold?: Maybe<Scalars['Boolean']>;
};

export type LastSevenDaySalesResponse = {
  __typename?: 'LastSevenDaySalesResponse';
  labels: Array<Scalars['String']>;
  data: Array<Scalars['Float']>;
};

export type TestSalesResponse = {
  __typename?: 'TestSalesResponse';
  sales?: Maybe<Array<TestSalesReport>>;
  hasMore?: Maybe<Scalars['Boolean']>;
  totalSale?: Maybe<Scalars['Float']>;
  totalProfit?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type TestSalesReport = {
  __typename?: 'TestSalesReport';
  id: Scalars['Int'];
  orderNumber: Scalars['Int'];
  brandName: Scalars['String'];
  country: Scalars['String'];
  productName: Scalars['String'];
  code: Scalars['String'];
  color: Scalars['String'];
  size: Scalars['String'];
  date: Scalars['String'];
  date_time: Scalars['String'];
  time: Scalars['String'];
  branchId: Scalars['Int'];
  paymentMethod?: Maybe<Scalars['JSON']>;
  isReturn?: Maybe<Scalars['Boolean']>;
  sale: Scalars['Float'];
  profit: Scalars['Float'];
};

export type SalesTargetResponse = {
  __typename?: 'SalesTargetResponse';
  bgSaleAchieved?: Maybe<Array<BgSaleAchieved>>;
  ogfSaleAchieved?: Maybe<Array<OgfSaleAchieved>>;
  ogffSaleAchieved?: Maybe<Array<OgffSaleAchieved>>;
  hcSaleAchieved?: Maybe<Array<HcSaleAchieved>>;
  hasMore?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type BgSaleAchieved = {
  __typename?: 'BgSaleAchieved';
  id: Scalars['Int'];
  year: Scalars['Int'];
  month: Scalars['Int'];
  target: Scalars['Float'];
  achieved: Scalars['Float'];
  achievedPercentage: Scalars['Float'];
};

export type OgfSaleAchieved = {
  __typename?: 'OgfSaleAchieved';
  id: Scalars['Int'];
  year: Scalars['Int'];
  month: Scalars['Int'];
  target: Scalars['Float'];
  achieved: Scalars['Float'];
  achievedPercentage: Scalars['Float'];
};

export type OgffSaleAchieved = {
  __typename?: 'OgffSaleAchieved';
  id: Scalars['Int'];
  year: Scalars['Int'];
  month: Scalars['Int'];
  target: Scalars['Float'];
  achieved: Scalars['Float'];
  achievedPercentage: Scalars['Float'];
};

export type HcSaleAchieved = {
  __typename?: 'HcSaleAchieved';
  id: Scalars['Int'];
  year: Scalars['Int'];
  month: Scalars['Int'];
  target: Scalars['Float'];
  achieved: Scalars['Float'];
  achievedPercentage: Scalars['Float'];
};

export type GetSalesTargetInput = {
  limit?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  getOrder: GetOrderResponse;
  createExchangeReceipt?: Maybe<ExchangeReceiptResponse>;
  createOrder: CreateOrderResponse;
  voidOrder: OrderVoidResponse;
  downloadUsersExcel: Scalars['String'];
  deleteOrder: DeleteOrderResponse;
  createPermission: CreatePermissionResponse;
  createRole: RoleResponse;
  editRole: RoleResponse;
  deleteRole: DeleteRoleResponse;
  assignPermissionsToRole: RoleResponse;
  getStockInvoice: StockInvoiceResponse;
  createStock: Scalars['Boolean'];
  updateStock: Scalars['Boolean'];
  adjustStock: Scalars['Boolean'];
  createUser: UserResponse;
  confirmUser: ConfirmUserResponse;
  updateUser: UserResponse;
  deleteUser: DeleteUserResponse;
  login: LoginUserResponse;
  refreshToken: RefreshTokenResponse;
  resetPassword: ResetPasswordResponse;
  logout: Scalars['Boolean'];
  createSupplier: SupplierCreateResponse;
  updateSupplier: UpdateSupplierResponse;
  deleteSupplier: DeleteSupplierResponse;
  payment: PaymentResponse;
  supplierReturn: Scalars['Boolean'];
  updateChequeStatus: ChequePayment;
  createBrand: BrandResponse;
  createProductType: CreateProductResponse;
  createProductVariance: CreateProductVarianceResponse;
  updateProductVariation: UpdateProductVariationResponse;
  updateBrand: BrandResponse;
  updateProductType: UpdateProductTypeResponse;
  deleteBrand: BrandResponse;
  deleteProductType: DeleteProductTypeResponse;
  deleteProductVariance: CreateProductVarianceResponse;
  getCustomer?: Maybe<Customer>;
  createCustomer: CustomerResponse;
  updateCustomer: CustomerResponse;
  deleteCustomer: CustomerResponse;
  createCategory: CategoryResponse;
  updateCategory: CategoryResponse;
  deleteCategory: CategoryResponse;
  updateSalesTarget: UpdateSalesTargetResponse;
  deleteSalesTarget: DeleteSalesTargetResponse;
  createSalesTarget?: Maybe<SalesTarget>;
};


export type MutationGetOrderArgs = {
  input: GetOrderInput;
};


export type MutationCreateExchangeReceiptArgs = {
  input: CreateExchangeReceiptInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationVoidOrderArgs = {
  id: Scalars['Float'];
};


export type MutationDownloadUsersExcelArgs = {
  input: SalesExportInput;
};


export type MutationDeleteOrderArgs = {
  id: Scalars['Float'];
};


export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};


export type MutationCreateRoleArgs = {
  input: RoleInput;
};


export type MutationEditRoleArgs = {
  name: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['Float'];
};


export type MutationAssignPermissionsToRoleArgs = {
  permissions: Array<Scalars['Float']>;
  id: Scalars['Float'];
};


export type MutationGetStockInvoiceArgs = {
  input: Scalars['Float'];
};


export type MutationCreateStockArgs = {
  input: CreateStockInput;
};


export type MutationUpdateStockArgs = {
  input: UpdateStockInput;
};


export type MutationAdjustStockArgs = {
  input: AdjustStockInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationConfirmUserArgs = {
  input: ConfirmUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  id: Scalars['Float'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Float'];
};


export type MutationLoginArgs = {
  input: CreateLoginInput;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};


export type MutationDeleteSupplierArgs = {
  id: Scalars['Float'];
};


export type MutationPaymentArgs = {
  input: PaymentInput;
};


export type MutationSupplierReturnArgs = {
  input: Array<SupplierReturnInput>;
};


export type MutationUpdateChequeStatusArgs = {
  input: ChequeUpdateStatusInput;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateProductTypeArgs = {
  input: CreateProductTypeInput;
};


export type MutationCreateProductVarianceArgs = {
  input: CreateProductVarianceInput;
};


export type MutationUpdateProductVariationArgs = {
  input: UpdateProductVarianceInput;
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};


export type MutationUpdateProductTypeArgs = {
  input: UpdateProductInput;
};


export type MutationDeleteBrandArgs = {
  input: Scalars['Float'];
};


export type MutationDeleteProductTypeArgs = {
  input: Scalars['Float'];
};


export type MutationDeleteProductVarianceArgs = {
  input: Scalars['Float'];
};


export type MutationGetCustomerArgs = {
  input: GetCustomerInput;
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationDeleteCustomerArgs = {
  input: Scalars['Float'];
};


export type MutationCreateCategoryArgs = {
  input: CategoryInput;
};


export type MutationUpdateCategoryArgs = {
  input: CategoryInput;
};


export type MutationDeleteCategoryArgs = {
  input: Scalars['Float'];
};


export type MutationUpdateSalesTargetArgs = {
  input: SalesTargetInput;
};


export type MutationDeleteSalesTargetArgs = {
  input: Scalars['Int'];
};


export type MutationCreateSalesTargetArgs = {
  input: SalesTargetInput;
};

export type GetOrderResponse = {
  __typename?: 'GetOrderResponse';
  order?: Maybe<Order>;
  points?: Maybe<Scalars['Float']>;
  user?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Item>>;
  customer?: Maybe<Customer>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  orderPayment?: Maybe<OrderPayment>;
  hcmLoyality?: Maybe<Scalars['Float']>;
  hcmOrderNumber?: Maybe<Scalars['Int']>;
};

export type GetOrderInput = {
  orderNumber: Scalars['Int'];
  isReturn?: Maybe<Scalars['Int']>;
};

export type ExchangeReceiptResponse = {
  __typename?: 'ExchangeReceiptResponse';
  returnReceiptNumber?: Maybe<Scalars['Int']>;
};

export type CreateExchangeReceiptInput = {
  returnReceiptNumber: Scalars['Int'];
  returnOrderId: Scalars['Int'];
  returnItems: Array<OrderItem>;
  date: Scalars['String'];
  branchId: Scalars['Int'];
};

export type OrderItem = {
  id: Scalars['Int'];
  quantity: Scalars['Float'];
  price: Scalars['Float'];
  discount?: Maybe<Scalars['Float']>;
  discountType?: Maybe<Scalars['Int']>;
  addToStock: Scalars['Int'];
  supplier?: Maybe<Scalars['Int']>;
  productVariationId?: Maybe<Scalars['Int']>;
  stockId?: Maybe<Scalars['Int']>;
};

export type CreateOrderResponse = {
  __typename?: 'CreateOrderResponse';
  orderNumber?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreateOrderInput = {
  customerId?: Maybe<Scalars['Int']>;
  branchId: Scalars['Int'];
  addPoints?: Maybe<Scalars['Float']>;
  hcmLoyality?: Maybe<Scalars['Float']>;
  hcmCustomerPhone?: Maybe<Scalars['String']>;
  settleInPoints?: Maybe<Scalars['Boolean']>;
  removePoints?: Maybe<Scalars['Float']>;
  amountPaid: Scalars['Float'];
  payments: Array<Payment>;
  items: Array<OrderItem>;
  isReturn?: Maybe<Scalars['Boolean']>;
  returnId?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
};

export type Payment = {
  paymentType?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
};

export type OrderVoidResponse = {
  __typename?: 'OrderVoidResponse';
  id?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type SalesExportInput = {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type DeleteOrderResponse = {
  __typename?: 'DeleteOrderResponse';
  order?: Maybe<Order>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreatePermissionResponse = {
  __typename?: 'CreatePermissionResponse';
  permission?: Maybe<Permission>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreatePermissionInput = {
  name: Scalars['String'];
};

export type RoleResponse = {
  __typename?: 'RoleResponse';
  role?: Maybe<Role>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type RoleInput = {
  name: Scalars['String'];
};

export type DeleteRoleResponse = {
  __typename?: 'DeleteRoleResponse';
  id?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreateStockInput = {
  date: Scalars['String'];
  requestId?: Maybe<Scalars['String']>;
  supplierId: Scalars['Int'];
  branchId: Scalars['Int'];
  items: Array<StockItem>;
  creditPeriod?: Maybe<Scalars['Int']>;
};

export type StockItem = {
  purchasePrice: Scalars['Float'];
  code: Scalars['String'];
  sizes: Array<StockSize>;
};

export type StockSize = {
  size: Scalars['Int'];
  quantity: Scalars['Int'];
  color: Scalars['String'];
};

export type UpdateStockInput = {
  spv_id: Scalars['Int'];
  supplier_id: Scalars['Int'];
  purchasePrice: Scalars['String'];
  date: Scalars['String'];
};

export type AdjustStockInput = {
  pv_id: Scalars['Int'];
  amount: Scalars['Int'];
  name: Scalars['String'];
  brand: Scalars['String'];
  category: Scalars['String'];
  code: Scalars['String'];
};

export type CreateUserInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleId: Scalars['Int'];
};

export type ConfirmUserResponse = {
  __typename?: 'ConfirmUserResponse';
  id?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type ConfirmUserInput = {
  id: Scalars['Int'];
  newPassword: Scalars['String'];
};

export type UpdateUserInput = {
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleId: Scalars['Int'];
};

export type DeleteUserResponse = {
  __typename?: 'DeleteUserResponse';
  id?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type LoginUserResponse = {
  __typename?: 'LoginUserResponse';
  access_token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreateLoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  access_token?: Maybe<Scalars['String']>;
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type SupplierCreateResponse = {
  __typename?: 'SupplierCreateResponse';
  supplier?: Maybe<Supplier>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreateSupplierInput = {
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Scalars['String']>>;
  creditPeriod?: Maybe<Scalars['Int']>;
};

export type UpdateSupplierResponse = {
  __typename?: 'UpdateSupplierResponse';
  supplier?: Maybe<Supplier>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type UpdateSupplierInput = {
  id: Scalars['Float'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<UpdateContacts>>;
  newContacts?: Maybe<Array<Scalars['String']>>;
  creditPeriod?: Maybe<Scalars['Int']>;
};

export type UpdateContacts = {
  id: Scalars['Int'];
  contact: Scalars['String'];
};

export type DeleteSupplierResponse = {
  __typename?: 'DeleteSupplierResponse';
  supplier?: Maybe<Supplier>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type PaymentInput = {
  supplierId: Scalars['Int'];
  amount: Scalars['Int'];
  date: Scalars['String'];
  paymentType: Scalars['Int'];
  cheque_number?: Maybe<Scalars['String']>;
  cheque_date?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['Int']>;
};

export type SupplierReturnInput = {
  supplierId: Scalars['Int'];
  stockId: Scalars['Int'];
  supplierReturnItems: Array<SupplierReturnItem>;
  date: Scalars['String'];
};

export type SupplierReturnItem = {
  productVariationId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type ChequeUpdateStatusInput = {
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type BrandResponse = {
  __typename?: 'BrandResponse';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreateBrandInput = {
  categoryIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  country: Scalars['String'];
};

export type CreateProductResponse = {
  __typename?: 'CreateProductResponse';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreateProductTypeInput = {
  name: Scalars['String'];
  code: Scalars['String'];
  size: Scalars['String'];
  productId: Scalars['Int'];
};

export type CreateProductVarianceResponse = {
  __typename?: 'CreateProductVarianceResponse';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CreateProductVarianceInput = {
  productId: Scalars['Int'];
  productVariationTypeId: Scalars['Int'];
  categoryId: Scalars['Int'];
  colors: Array<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type UpdateProductVariationResponse = {
  __typename?: 'UpdateProductVariationResponse';
  productVariation?: Maybe<ProductVariation>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type UpdateProductVarianceInput = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  categoryId: Scalars['Int'];
  productVariationTypeId: Scalars['Int'];
  size: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type UpdateBrandInput = {
  id?: Maybe<Scalars['Int']>;
  categoryIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  country: Scalars['String'];
};

export type UpdateProductTypeResponse = {
  __typename?: 'UpdateProductTypeResponse';
  productVariationType?: Maybe<ProductVariationType>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type UpdateProductInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
  size: Scalars['String'];
  productId: Scalars['Int'];
};

export type DeleteProductTypeResponse = {
  __typename?: 'DeleteProductTypeResponse';
  productVairationType?: Maybe<ProductVariationType>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type GetCustomerInput = {
  phone: Scalars['String'];
};

export type CustomerResponse = {
  __typename?: 'CustomerResponse';
  customer?: Maybe<Customer>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CustomerInput = {
  id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  joined: Scalars['String'];
  phone: Scalars['String'];
  dob?: Maybe<Scalars['String']>;
  nic?: Maybe<Scalars['String']>;
  branchId: Scalars['Int'];
};

export type CategoryResponse = {
  __typename?: 'CategoryResponse';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type CategoryInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type UpdateSalesTargetResponse = {
  __typename?: 'UpdateSalesTargetResponse';
  bgSaleAchieved?: Maybe<BgSaleAchieved>;
  ogfSaleAchieved?: Maybe<OgfSaleAchieved>;
  ogffSaleAchieved?: Maybe<OgffSaleAchieved>;
  hcSaleAchieved?: Maybe<HcSaleAchieved>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type SalesTargetInput = {
  id?: Maybe<Scalars['Int']>;
  year: Scalars['String'];
  month: Scalars['String'];
  target: Scalars['String'];
};

export type DeleteSalesTargetResponse = {
  __typename?: 'DeleteSalesTargetResponse';
  bgSaleAchieved?: Maybe<BgSaleAchieved>;
  ogfSaleAchieved?: Maybe<OgfSaleAchieved>;
  ogffSaleAchieved?: Maybe<OgffSaleAchieved>;
  hcSaleAchieved?: Maybe<HcSaleAchieved>;
  errors?: Maybe<Array<ErrorResponse>>;
};

export type SalesTarget = {
  __typename?: 'SalesTarget';
  id: Scalars['Int'];
  branch: Branch;
  year: Scalars['Int'];
  month: Scalars['Int'];
  target: Scalars['Float'];
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'approved' | 'created_at' | 'updated_at'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
        & { permissions?: Maybe<Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'name' | 'parent'>
        )>> }
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginUserResponse' }
    & Pick<LoginUserResponse, 'access_token'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'approved' | 'created_at' | 'updated_at'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
        & { permissions?: Maybe<Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'name'>
        )>> }
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message' | 'field'>
    )>> }
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  id: Scalars['Float'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordResponse' }
    & Pick<ResetPasswordResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken: (
    { __typename?: 'RefreshTokenResponse' }
    & Pick<RefreshTokenResponse, 'access_token'>
  ) }
);

export type ConfirmUserMutationVariables = Exact<{
  input: ConfirmUserInput;
}>;


export type ConfirmUserMutation = (
  { __typename?: 'Mutation' }
  & { confirmUser: (
    { __typename?: 'ConfirmUserResponse' }
    & Pick<ConfirmUserResponse, 'id'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type GetBranchesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBranchesQuery = (
  { __typename?: 'Query' }
  & { getBranches: Array<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'id' | 'name'>
  )> }
);

export type GetCustomerMutationVariables = Exact<{
  phone: Scalars['String'];
}>;


export type GetCustomerMutation = (
  { __typename?: 'Mutation' }
  & { getCustomer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'title' | 'first_name' | 'last_name' | 'phone' | 'points' | 'joined' | 'nic' | 'dob'>
    & { branch: (
      { __typename?: 'Branch' }
      & Pick<Branch, 'id' | 'name'>
    ) }
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'CustomerResponse' }
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'title' | 'first_name' | 'last_name' | 'phone' | 'points' | 'joined' | 'nic' | 'dob'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type GetCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { getCustomers: (
    { __typename?: 'CustomerListResponse' }
    & { customers?: Maybe<Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'title' | 'first_name' | 'last_name' | 'phone' | 'points' | 'joined' | 'nic' | 'dob'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ) }
    )>>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteCustomerMutationVariables = Exact<{
  input: Scalars['Float'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomer: (
    { __typename?: 'CustomerResponse' }
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'title' | 'first_name' | 'last_name' | 'phone' | 'points' | 'joined' | 'nic' | 'dob'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'CustomerResponse' }
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'title' | 'first_name' | 'last_name' | 'phone' | 'points' | 'joined' | 'nic' | 'dob' | 'created_at'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = (
  { __typename?: 'Query' }
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'name' | 'parent'>
  )> }
);

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
    & { subCategories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>> }
  )> }
);

export type GetProductCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductCountriesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUniqueProductCountries'>
);

export type GetBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBrandsQuery = (
  { __typename?: 'Query' }
  & { getBrands: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'country'>
    & { categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>> }
  )> }
);

export type GetproductsForReceiptQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetproductsForReceiptQuery = (
  { __typename?: 'Query' }
  & { getProductsForReceipt: Array<(
    { __typename?: 'ProductListResponse' }
    & Pick<ProductListResponse, 'id' | 'barcode' | 'discount' | 'discount_type' | 'product_size' | 'size' | 'name' | 'color' | 'code' | 'quantity' | 'price' | 'sub_total' | 'available' | 'in_stock'>
  )> }
);

export type GetProductVariationListQueryVariables = Exact<{
  input?: Maybe<ProductVariationListInput>;
}>;


export type GetProductVariationListQuery = (
  { __typename?: 'Query' }
  & { getProductVariationList: (
    { __typename?: 'ProductVariationResponse' }
    & Pick<ProductVariationResponse, 'hasMore'>
    & { productVariaitons: Array<(
      { __typename?: 'ProductVariationResponseType' }
      & Pick<ProductVariationResponseType, 'product_id' | 'category_id' | 'type_id' | 'id' | 'name' | 'code' | 'size' | 'color' | 'price'>
    )> }
  ) }
);

export type GetProductTypesQueryVariables = Exact<{
  input?: Maybe<ProductVariationTypesInput>;
}>;


export type GetProductTypesQuery = (
  { __typename?: 'Query' }
  & { getProductTypes: (
    { __typename?: 'ProductVariationTypeResponse' }
    & Pick<ProductVariationTypeResponse, 'hasMore'>
    & { productVariationTypes: Array<(
      { __typename?: 'ProductVariationType' }
      & Pick<ProductVariationType, 'id' | 'name' | 'code' | 'size'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type GetUniqueProductSizesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUniqueProductSizesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUniqueProductSizes'>
);

export type GetUniqueProductColorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUniqueProductColorsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUniqueProductColors'>
);

export type GetUniqueProductCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUniqueProductCodesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUniqueProductCodes'>
);

export type GetUniqueProductNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUniqueProductNamesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUniqueProductNames'>
);

export type SearchProductTypesQueryVariables = Exact<{
  input: Scalars['Float'];
}>;


export type SearchProductTypesQuery = (
  { __typename?: 'Query' }
  & { searchProductTypes: Array<(
    { __typename?: 'ProductVariationType' }
    & Pick<ProductVariationType, 'id' | 'name' | 'code'>
  )> }
);

export type UpdateProductVariationMutationVariables = Exact<{
  input: UpdateProductVarianceInput;
}>;


export type UpdateProductVariationMutation = (
  { __typename?: 'Mutation' }
  & { updateProductVariation: (
    { __typename?: 'UpdateProductVariationResponse' }
    & { productVariation?: Maybe<(
      { __typename?: 'ProductVariation' }
      & Pick<ProductVariation, 'id' | 'barcode' | 'name' | 'color' | 'price_bg' | 'price_ogf' | 'price_ogff' | 'price_hc'>
      & { category: (
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      ), product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type UpdateBrandMutationVariables = Exact<{
  input: UpdateBrandInput;
}>;


export type UpdateBrandMutation = (
  { __typename?: 'Mutation' }
  & { updateBrand: (
    { __typename?: 'BrandResponse' }
    & Pick<BrandResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type UpdateProductTypeMutationVariables = Exact<{
  input: UpdateProductInput;
}>;


export type UpdateProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateProductType: (
    { __typename?: 'UpdateProductTypeResponse' }
    & { productVariationType?: Maybe<(
      { __typename?: 'ProductVariationType' }
      & Pick<ProductVariationType, 'id' | 'name' | 'code' | 'size'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteCategoryMutationVariables = Exact<{
  input: Scalars['Float'];
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory: (
    { __typename?: 'CategoryResponse' }
    & Pick<CategoryResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteBrandMutationVariables = Exact<{
  input: Scalars['Float'];
}>;


export type DeleteBrandMutation = (
  { __typename?: 'Mutation' }
  & { deleteBrand: (
    { __typename?: 'BrandResponse' }
    & Pick<BrandResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteProductTypeMutationVariables = Exact<{
  input: Scalars['Float'];
}>;


export type DeleteProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteProductType: (
    { __typename?: 'DeleteProductTypeResponse' }
    & { productVairationType?: Maybe<(
      { __typename?: 'ProductVariationType' }
      & Pick<ProductVariationType, 'id' | 'name' | 'code' | 'size'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteProductVarianceMutationVariables = Exact<{
  input: Scalars['Float'];
}>;


export type DeleteProductVarianceMutation = (
  { __typename?: 'Mutation' }
  & { deleteProductVariance: (
    { __typename?: 'CreateProductVarianceResponse' }
    & Pick<CreateProductVarianceResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type GetOrderMutationVariables = Exact<{
  input: GetOrderInput;
}>;


export type GetOrderMutation = (
  { __typename?: 'Mutation' }
  & { getOrder: (
    { __typename?: 'GetOrderResponse' }
    & Pick<GetOrderResponse, 'points' | 'hcmLoyality' | 'hcmOrderNumber' | 'user'>
    & { orderPayment?: Maybe<(
      { __typename?: 'OrderPayment' }
      & Pick<OrderPayment, 'amount_paid' | 'points'>
    )>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'order_number' | 'date'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ) }
    )>, items?: Maybe<Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'name' | 'stockDate' | 'brand' | 'code' | 'quantity' | 'price' | 'discount' | 'discountType' | 'addToStock' | 'productVariationId'>
    )>>, customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'title' | 'last_name' | 'phone' | 'points'>
    )>, paymentMethods?: Maybe<Array<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'type' | 'amount'>
    )>> }
  ) }
);

export type GetOrderNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrderNumberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOrderNumber'>
);

export type GetReturnReceiptNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReturnReceiptNumberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getReturnReceiptNumber'>
);

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'CreateOrderResponse' }
    & Pick<CreateOrderResponse, 'orderNumber'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type OrderPaymentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderPaymentTypesQuery = (
  { __typename?: 'Query' }
  & { orderPaymentTypes: Array<(
    { __typename?: 'OrderPaymentType' }
    & Pick<OrderPaymentType, 'id' | 'name'>
  )> }
);

export type GetReturnedItemsQueryVariables = Exact<{
  input: Scalars['Float'];
}>;


export type GetReturnedItemsQuery = (
  { __typename?: 'Query' }
  & { getReturnedItems: Array<(
    { __typename?: 'ReturnItem' }
    & Pick<ReturnItem, 'id' | 'quantity'>
  )> }
);

export type GetOrderListQueryVariables = Exact<{
  input: OrderListInput;
}>;


export type GetOrderListQuery = (
  { __typename?: 'Query' }
  & { getOrderList: (
    { __typename?: 'OrderListResponse' }
    & Pick<OrderListResponse, 'hasMore'>
    & { orderList: Array<(
      { __typename?: 'OrderList' }
      & Pick<OrderList, 'id' | 'orderNumber' | 'date' | 'void' | 'cashier' | 'total'>
    )> }
  ) }
);

export type VoidOrderMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type VoidOrderMutation = (
  { __typename?: 'Mutation' }
  & { voidOrder: (
    { __typename?: 'OrderVoidResponse' }
    & Pick<OrderVoidResponse, 'id'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrder: (
    { __typename?: 'DeleteOrderResponse' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type CreateExchangeReceiptMutationVariables = Exact<{
  input: CreateExchangeReceiptInput;
}>;


export type CreateExchangeReceiptMutation = (
  { __typename?: 'Mutation' }
  & { createExchangeReceipt?: Maybe<(
    { __typename?: 'ExchangeReceiptResponse' }
    & Pick<ExchangeReceiptResponse, 'returnReceiptNumber'>
  )> }
);

export type GetExchangeReceiptsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExchangeReceiptsQuery = (
  { __typename?: 'Query' }
  & { getExchangeReceipts: Array<(
    { __typename?: 'Return' }
    & Pick<Return, 'id' | 'return_receipt_number' | 'date'>
    & { associated: (
      { __typename?: 'Order' }
      & Pick<Order, 'order_number'>
    ) }
  )> }
);

export type GetExchangeReceiptInfoQueryVariables = Exact<{
  input: Scalars['Float'];
}>;


export type GetExchangeReceiptInfoQuery = (
  { __typename?: 'Query' }
  & { getExchangeReceiptInfo?: Maybe<(
    { __typename?: 'ExchangeReceiptInfoResponse' }
    & Pick<ExchangeReceiptInfoResponse, 'returnId' | 'branch' | 'orderNumber' | 'storeCredit' | 'createdAt' | 'date'>
    & { items?: Maybe<Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'name' | 'quantity' | 'price' | 'discount' | 'discountType'>
    )>> }
  )> }
);

export type GetReturnedOrderQueryVariables = Exact<{
  input: Scalars['Float'];
}>;


export type GetReturnedOrderQuery = (
  { __typename?: 'Query' }
  & { getReturnedOrder?: Maybe<(
    { __typename?: 'GetReturnOrderResponse' }
    & Pick<GetReturnOrderResponse, 'points' | 'hcmLoyality' | 'user' | 'returnedOrderNumber' | 'returnedReceiptAmount'>
    & { orderPayment?: Maybe<(
      { __typename?: 'OrderPayment' }
      & Pick<OrderPayment, 'amount_paid' | 'points'>
    )>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'order_number' | 'date'>
    )>, items?: Maybe<Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'name' | 'quantity' | 'price' | 'discount' | 'discountType' | 'addToStock' | 'productVariationId'>
    )>>, customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'title' | 'last_name'>
    )>, paymentMethods?: Maybe<Array<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'type' | 'amount'>
    )>> }
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  input: RoleInput;
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole: (
    { __typename?: 'RoleResponse' }
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
    & { permissions?: Maybe<Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'name' | 'parent'>
    )>> }
  )> }
);

export type AssignPermissionsToRoleMutationVariables = Exact<{
  id: Scalars['Float'];
  permissions: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type AssignPermissionsToRoleMutation = (
  { __typename?: 'Mutation' }
  & { assignPermissionsToRole: (
    { __typename?: 'RoleResponse' }
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type EditRoleMutationVariables = Exact<{
  id: Scalars['Float'];
  name: Scalars['String'];
}>;


export type EditRoleMutation = (
  { __typename?: 'Mutation' }
  & { editRole: (
    { __typename?: 'RoleResponse' }
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole: (
    { __typename?: 'DeleteRoleResponse' }
    & Pick<DeleteRoleResponse, 'id'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type GetSalesQueryVariables = Exact<{
  input: GetSalesInput;
}>;


export type GetSalesQuery = (
  { __typename?: 'Query' }
  & { getSales: (
    { __typename?: 'SalesResponse' }
    & Pick<SalesResponse, 'hasMore' | 'totalSale' | 'totalProfit' | 'itemsSold'>
    & { sales?: Maybe<Array<(
      { __typename?: 'SalesReport' }
      & Pick<SalesReport, 'id' | 'orderNumber' | 'brandName' | 'country' | 'productName' | 'code' | 'color' | 'size' | 'date' | 'time' | 'date_time' | 'supplierId' | 'purchasePrice' | 'sellingPrice' | 'discount' | 'totalQuantity' | 'returnQuantity' | 'paymentMethod' | 'isReturn' | 'associatedOrderNumber' | 'return'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type GetSalesAchievedQueryVariables = Exact<{
  input: GetSalesTargetInput;
}>;


export type GetSalesAchievedQuery = (
  { __typename?: 'Query' }
  & { getSalesAchieved: (
    { __typename?: 'SalesTargetResponse' }
    & Pick<SalesTargetResponse, 'hasMore'>
    & { bgSaleAchieved?: Maybe<Array<(
      { __typename?: 'BgSaleAchieved' }
      & Pick<BgSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>>, ogfSaleAchieved?: Maybe<Array<(
      { __typename?: 'OgfSaleAchieved' }
      & Pick<OgfSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>>, ogffSaleAchieved?: Maybe<Array<(
      { __typename?: 'OgffSaleAchieved' }
      & Pick<OgffSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>>, hcSaleAchieved?: Maybe<Array<(
      { __typename?: 'HcSaleAchieved' }
      & Pick<HcSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type GetTestSalesReportQueryVariables = Exact<{
  input: GetSalesInput;
}>;


export type GetTestSalesReportQuery = (
  { __typename?: 'Query' }
  & { getTestSalesReport: (
    { __typename?: 'TestSalesResponse' }
    & Pick<TestSalesResponse, 'hasMore' | 'totalSale' | 'totalProfit'>
    & { sales?: Maybe<Array<(
      { __typename?: 'TestSalesReport' }
      & Pick<TestSalesReport, 'id' | 'orderNumber' | 'brandName' | 'country' | 'productName' | 'code' | 'color' | 'size' | 'date' | 'date_time' | 'time' | 'paymentMethod' | 'sale' | 'profit' | 'isReturn'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type CreateSalesTargetMutationVariables = Exact<{
  input: SalesTargetInput;
}>;


export type CreateSalesTargetMutation = (
  { __typename?: 'Mutation' }
  & { createSalesTarget?: Maybe<(
    { __typename?: 'SalesTarget' }
    & Pick<SalesTarget, 'id' | 'year' | 'month' | 'target' | 'created_at'>
  )> }
);

export type UpdateSalesTargetMutationVariables = Exact<{
  input: SalesTargetInput;
}>;


export type UpdateSalesTargetMutation = (
  { __typename?: 'Mutation' }
  & { updateSalesTarget: (
    { __typename?: 'UpdateSalesTargetResponse' }
    & { bgSaleAchieved?: Maybe<(
      { __typename?: 'BgSaleAchieved' }
      & Pick<BgSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>, ogfSaleAchieved?: Maybe<(
      { __typename?: 'OgfSaleAchieved' }
      & Pick<OgfSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>, ogffSaleAchieved?: Maybe<(
      { __typename?: 'OgffSaleAchieved' }
      & Pick<OgffSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>, hcSaleAchieved?: Maybe<(
      { __typename?: 'HcSaleAchieved' }
      & Pick<HcSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteSalesTargetMutationVariables = Exact<{
  input: Scalars['Int'];
}>;


export type DeleteSalesTargetMutation = (
  { __typename?: 'Mutation' }
  & { deleteSalesTarget: (
    { __typename?: 'DeleteSalesTargetResponse' }
    & { bgSaleAchieved?: Maybe<(
      { __typename?: 'BgSaleAchieved' }
      & Pick<BgSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>, ogfSaleAchieved?: Maybe<(
      { __typename?: 'OgfSaleAchieved' }
      & Pick<OgfSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>, ogffSaleAchieved?: Maybe<(
      { __typename?: 'OgffSaleAchieved' }
      & Pick<OgffSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>, hcSaleAchieved?: Maybe<(
      { __typename?: 'HcSaleAchieved' }
      & Pick<HcSaleAchieved, 'id' | 'year' | 'month' | 'target' | 'achieved' | 'achievedPercentage'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type GetLastSevenDaysSalesQueryVariables = Exact<{
  input: GetSalesInput;
}>;


export type GetLastSevenDaysSalesQuery = (
  { __typename?: 'Query' }
  & { getLastSevenDaysSales: (
    { __typename?: 'LastSevenDaySalesResponse' }
    & Pick<LastSevenDaySalesResponse, 'labels' | 'data'>
  ) }
);

export type ExportHcmSalesMutationVariables = Exact<{
  input: SalesExportInput;
}>;


export type ExportHcmSalesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadUsersExcel'>
);

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )> }
);

export type CreateStockMutationVariables = Exact<{
  input: CreateStockInput;
}>;


export type CreateStockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStock'>
);

export type CreateCategoryMutationVariables = Exact<{
  input: CategoryInput;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'CategoryResponse' }
    & Pick<CategoryResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type UpdateCategoryMutationVariables = Exact<{
  input: CategoryInput;
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: (
    { __typename?: 'CategoryResponse' }
    & Pick<CategoryResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;


export type CreateBrandMutation = (
  { __typename?: 'Mutation' }
  & { createBrand: (
    { __typename?: 'BrandResponse' }
    & Pick<BrandResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type CreateProductTypeMutationVariables = Exact<{
  input: CreateProductTypeInput;
}>;


export type CreateProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { createProductType: (
    { __typename?: 'CreateProductResponse' }
    & Pick<CreateProductResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type CreateProductVariationMutationVariables = Exact<{
  input: CreateProductVarianceInput;
}>;


export type CreateProductVariationMutation = (
  { __typename?: 'Mutation' }
  & { createProductVariance: (
    { __typename?: 'CreateProductVarianceResponse' }
    & Pick<CreateProductVarianceResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type AvaiableStockQueryVariables = Exact<{
  input?: Maybe<StockAvailableInput>;
}>;


export type AvaiableStockQuery = (
  { __typename?: 'Query' }
  & { getAvaiableStock: (
    { __typename?: 'StockAvailableResponse' }
    & Pick<StockAvailableResponse, 'hasMore'>
    & { bgAvailable?: Maybe<Array<(
      { __typename?: 'BgStockAvailable' }
      & Pick<BgStockAvailable, 'id' | 'pv_id' | 'category' | 'brand' | 'name' | 'code' | 'available' | 'in_stock'>
    )>>, ogfAvailable?: Maybe<Array<(
      { __typename?: 'OgfStockAvailable' }
      & Pick<OgfStockAvailable, 'id' | 'pv_id' | 'category' | 'brand' | 'name' | 'code' | 'available' | 'in_stock'>
    )>>, ogffAvailable?: Maybe<Array<(
      { __typename?: 'OgffStockAvailable' }
      & Pick<OgffStockAvailable, 'id' | 'pv_id' | 'category' | 'brand' | 'name' | 'code' | 'available' | 'in_stock'>
    )>>, hcAvailable?: Maybe<Array<(
      { __typename?: 'HcStockAvailable' }
      & Pick<HcStockAvailable, 'id' | 'pv_id' | 'category' | 'brand' | 'name' | 'code' | 'available' | 'in_stock'>
    )>> }
  ) }
);

export type GetStockValueQueryVariables = Exact<{
  input: StockValueInput;
}>;


export type GetStockValueQuery = (
  { __typename?: 'Query' }
  & { getStockValue: (
    { __typename?: 'StockValueResponse' }
    & Pick<StockValueResponse, 'total' | 'quantity' | 'hasMore'>
    & { bgValue?: Maybe<Array<(
      { __typename?: 'BgStockValue' }
      & Pick<BgStockValue, 'id' | 'country' | 'supplier' | 'brand' | 'code' | 'name' | 'color' | 'quantity' | 'purchase_price' | 'total'>
    )>>, ogfValue?: Maybe<Array<(
      { __typename?: 'OgfStockValue' }
      & Pick<OgfStockValue, 'id' | 'country' | 'supplier' | 'brand' | 'code' | 'name' | 'color' | 'quantity' | 'purchase_price' | 'total'>
    )>>, ogffValue?: Maybe<Array<(
      { __typename?: 'OgffStockValue' }
      & Pick<OgffStockValue, 'id' | 'country' | 'supplier' | 'brand' | 'code' | 'name' | 'color' | 'quantity' | 'purchase_price' | 'total'>
    )>>, hcValue?: Maybe<Array<(
      { __typename?: 'HcStockValue' }
      & Pick<HcStockValue, 'id' | 'country' | 'supplier' | 'brand' | 'code' | 'name' | 'color' | 'quantity' | 'purchase_price' | 'total'>
    )>> }
  ) }
);

export type GetStockVarianceQueryVariables = Exact<{
  input: StockVarianceInput;
}>;


export type GetStockVarianceQuery = (
  { __typename?: 'Query' }
  & { getStockVariance: (
    { __typename?: 'StockVarianceResponse' }
    & Pick<StockVarianceResponse, 'hasMore'>
    & { stockVariance: Array<(
      { __typename?: 'StockVariance' }
      & Pick<StockVariance, 'id' | 'code' | 'name' | 'color' | 'size' | 'spv_id' | 'quantity' | 'purchase_price' | 'total' | 'supplier_id' | 'supplier_name' | 'branch' | 'date'>
    )> }
  ) }
);

export type GetReturnableStocksQueryVariables = Exact<{
  input: Scalars['Float'];
}>;


export type GetReturnableStocksQuery = (
  { __typename?: 'Query' }
  & { getReturnableStocks: Array<(
    { __typename?: 'ReturnableStocksResponse' }
    & Pick<ReturnableStocksResponse, 'stock_id' | 'date' | 'product_variation_id' | 'name' | 'quantity' | 'returnable'>
  )> }
);

export type GetStockInvoicesQueryVariables = Exact<{
  input?: Maybe<StockInvoiceInput>;
}>;


export type GetStockInvoicesQuery = (
  { __typename?: 'Query' }
  & { getStockInvoices: (
    { __typename?: 'StockInvoiceResponse' }
    & Pick<StockInvoiceResponse, 'hasMore'>
    & { bgInvoice?: Maybe<Array<(
      { __typename?: 'BGInvoice' }
      & Pick<BgInvoice, 'id' | 'stock_id' | 'date' | 'supplier' | 'category' | 'code' | 'color' | 'quantity'>
    )>>, ogfInvoice?: Maybe<Array<(
      { __typename?: 'OGFInvoice' }
      & Pick<OgfInvoice, 'id' | 'stock_id' | 'date' | 'supplier' | 'category' | 'code' | 'color' | 'quantity'>
    )>>, ogffInvoice?: Maybe<Array<(
      { __typename?: 'OGFFInvoice' }
      & Pick<OgffInvoice, 'id' | 'stock_id' | 'date' | 'supplier' | 'category' | 'code' | 'color' | 'quantity'>
    )>>, hcInvoice?: Maybe<Array<(
      { __typename?: 'HCMInvoice' }
      & Pick<HcmInvoice, 'id' | 'stock_id' | 'date' | 'supplier' | 'category' | 'code' | 'color' | 'quantity'>
    )>> }
  ) }
);

export type GetStockInvoiceMutationVariables = Exact<{
  input: Scalars['Float'];
}>;


export type GetStockInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { getStockInvoice: (
    { __typename?: 'StockInvoiceResponse' }
    & { bgInvoice?: Maybe<Array<(
      { __typename?: 'BGInvoice' }
      & Pick<BgInvoice, 'id' | 'stock_id' | 'date' | 'supplier' | 'category' | 'code' | 'color' | 'quantity'>
    )>>, ogfInvoice?: Maybe<Array<(
      { __typename?: 'OGFInvoice' }
      & Pick<OgfInvoice, 'id' | 'stock_id' | 'date' | 'supplier' | 'category' | 'code' | 'color' | 'quantity'>
    )>>, ogffInvoice?: Maybe<Array<(
      { __typename?: 'OGFFInvoice' }
      & Pick<OgffInvoice, 'id' | 'stock_id' | 'date' | 'supplier' | 'category' | 'code' | 'color' | 'quantity'>
    )>>, hcInvoice?: Maybe<Array<(
      { __typename?: 'HCMInvoice' }
      & Pick<HcmInvoice, 'id' | 'stock_id' | 'date' | 'supplier' | 'category' | 'code' | 'color' | 'quantity'>
    )>> }
  ) }
);

export type UpdateStockMutationVariables = Exact<{
  input: UpdateStockInput;
}>;


export type UpdateStockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStock'>
);

export type AdjustStockMutationVariables = Exact<{
  input: AdjustStockInput;
}>;


export type AdjustStockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adjustStock'>
);

export type GetUpdateStockQuantityQueryVariables = Exact<{
  input: UpdateStockQuantityInput;
}>;


export type GetUpdateStockQuantityQuery = (
  { __typename?: 'Query' }
  & { getUpdateStockQuantity: (
    { __typename?: 'UpdateStockQuantityResponse' }
    & Pick<UpdateStockQuantityResponse, 'hasMore'>
    & { stock?: Maybe<Array<(
      { __typename?: 'UpdateStockQuantity' }
      & Pick<UpdateStockQuantity, 'id' | 'name' | 'brand' | 'category' | 'code' | 'quantity' | 'pv_id' | 'spv_id' | 'supplier' | 'date'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username' | 'firstName'>
      ) }
    )>> }
  ) }
);

export type GetSupplierListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupplierListQuery = (
  { __typename?: 'Query' }
  & { getSupplierList: Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'address' | 'creditPeriod'>
    & { supplierAdvance?: Maybe<(
      { __typename?: 'SupplierAdvance' }
      & Pick<SupplierAdvance, 'id' | 'amount'>
    )>, supplierContacts?: Maybe<Array<(
      { __typename?: 'SupplierContact' }
      & Pick<SupplierContact, 'id' | 'contact'>
    )>> }
  )> }
);

export type GetPaymentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentTypesQuery = (
  { __typename?: 'Query' }
  & { getPaymentTypes: Array<(
    { __typename?: 'StockPaymentType' }
    & Pick<StockPaymentType, 'id' | 'name'>
  )> }
);

export type GetBanksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBanksQuery = (
  { __typename?: 'Query' }
  & { getBanks: Array<(
    { __typename?: 'Bank' }
    & Pick<Bank, 'id' | 'name'>
  )> }
);

export type GetOutstandingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOutstandingQuery = (
  { __typename?: 'Query' }
  & { getOutstanding: Array<(
    { __typename?: 'SupplierOutstanding' }
    & Pick<SupplierOutstanding, 'id' | 'name' | 'address' | 'outstanding' | 'last_settled_date' | 'days'>
  )> }
);

export type SupplierPaymentHistoryQueryVariables = Exact<{
  input: PaymentHistoryInput;
}>;


export type SupplierPaymentHistoryQuery = (
  { __typename?: 'Query' }
  & { paymentHistory: Array<(
    { __typename?: 'SupplierPayment' }
    & Pick<SupplierPayment, 'id' | 'date' | 'amount'>
    & { stockPaymentType: (
      { __typename?: 'StockPaymentType' }
      & Pick<StockPaymentType, 'name'>
    ), supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
    )>, paymentInfo?: Maybe<(
      { __typename?: 'SupplierPaymentInfo' }
      & Pick<SupplierPaymentInfo, 'id' | 'cheque_number' | 'cheque_date'>
      & { bank?: Maybe<(
        { __typename?: 'Bank' }
        & Pick<Bank, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ChequePaymentsQueryVariables = Exact<{
  input: ChequePaymentInput;
}>;


export type ChequePaymentsQuery = (
  { __typename?: 'Query' }
  & { chequePayments: (
    { __typename?: 'ChequePaymentResponse' }
    & Pick<ChequePaymentResponse, 'hasMore' | 'totalAmount'>
    & { chequePayments: Array<(
      { __typename?: 'ChequePayment' }
      & Pick<ChequePayment, 'id' | 'supplierId' | 'amount' | 'paymentDate' | 'chequeDate' | 'status'>
    )> }
  ) }
);

export type CreateSupplierMutationVariables = Exact<{
  input: CreateSupplierInput;
}>;


export type CreateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createSupplier: (
    { __typename?: 'SupplierCreateResponse' }
    & { supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name' | 'address' | 'creditPeriod'>
      & { supplierContacts?: Maybe<Array<(
        { __typename?: 'SupplierContact' }
        & Pick<SupplierContact, 'id' | 'contact'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type PaymentMutationVariables = Exact<{
  input: PaymentInput;
}>;


export type PaymentMutation = (
  { __typename?: 'Mutation' }
  & { payment: (
    { __typename?: 'PaymentResponse' }
    & Pick<PaymentResponse, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type UpdateSupplierMutationVariables = Exact<{
  input: UpdateSupplierInput;
}>;


export type UpdateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplier: (
    { __typename?: 'UpdateSupplierResponse' }
    & { supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name' | 'address' | 'creditPeriod'>
      & { supplierContacts?: Maybe<Array<(
        { __typename?: 'SupplierContact' }
        & Pick<SupplierContact, 'id' | 'contact'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type DeleteSupplierMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteSupplierMutation = (
  { __typename?: 'Mutation' }
  & { deleteSupplier: (
    { __typename?: 'DeleteSupplierResponse' }
    & { supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type StockSettlementQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type StockSettlementQuery = (
  { __typename?: 'Query' }
  & { stockSettlement: Array<(
    { __typename?: 'StockSettlement' }
    & Pick<StockSettlement, 'id' | 'stock_id' | 'stock_date' | 'creditPeriod' | 'supplier_id' | 'branch_id' | 'payable' | 'paid' | 'payment_date' | 'is_settled' | 'settled_date'>
  )> }
);

export type SupplierReturnMutationVariables = Exact<{
  input: Array<SupplierReturnInput> | SupplierReturnInput;
}>;


export type SupplierReturnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'supplierReturn'>
);

export type UpdateChequeStatusMutationVariables = Exact<{
  input: ChequeUpdateStatusInput;
}>;


export type UpdateChequeStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateChequeStatus: (
    { __typename?: 'ChequePayment' }
    & Pick<ChequePayment, 'id' | 'branchId' | 'supplierId' | 'amount' | 'paymentDate' | 'chequeDate' | 'status'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'approved' | 'created_at'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Float'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'approved' | 'created_at'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'name'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'approved' | 'created_at'>
    & { branch: (
      { __typename?: 'Branch' }
      & Pick<Branch, 'id' | 'name'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  )> }
);

export type SendPingQueryVariables = Exact<{ [key: string]: never; }>;


export type SendPingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sendPing'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'DeleteUserResponse' }
    & Pick<DeleteUserResponse, 'id'>
    & { errors?: Maybe<Array<(
      { __typename?: 'ErrorResponse' }
      & Pick<ErrorResponse, 'message'>
    )>> }
  ) }
);


export const MeDocument = gql`
    query me {
  me {
    user {
      id
      username
      firstName
      lastName
      approved
      created_at
      updated_at
      branch {
        id
        name
      }
      role {
        id
        name
        permissions {
          id
          name
          parent
        }
      }
    }
    errors {
      message
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
    mutation login($username: String!, $password: String!) {
  login(input: {username: $username, password: $password}) {
    access_token
    user {
      id
      username
      firstName
      lastName
      approved
      branch {
        id
        name
      }
      created_at
      updated_at
      role {
        id
        name
        permissions {
          id
          name
        }
      }
    }
    errors {
      message
      field
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $id: Float!) {
  resetPassword(password: $password, id: $id) {
    message
    errors {
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken {
  refreshToken {
    access_token
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ConfirmUserDocument = gql`
    mutation confirmUser($input: ConfirmUserInput!) {
  confirmUser(input: $input) {
    id
    errors {
      message
    }
  }
}
    `;
export type ConfirmUserMutationFn = Apollo.MutationFunction<ConfirmUserMutation, ConfirmUserMutationVariables>;

/**
 * __useConfirmUserMutation__
 *
 * To run a mutation, you first call `useConfirmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserMutation, { data, loading, error }] = useConfirmUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUserMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUserMutation, ConfirmUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmUserMutation, ConfirmUserMutationVariables>(ConfirmUserDocument, options);
      }
export type ConfirmUserMutationHookResult = ReturnType<typeof useConfirmUserMutation>;
export type ConfirmUserMutationResult = Apollo.MutationResult<ConfirmUserMutation>;
export type ConfirmUserMutationOptions = Apollo.BaseMutationOptions<ConfirmUserMutation, ConfirmUserMutationVariables>;
export const GetBranchesDocument = gql`
    query getBranches {
  getBranches {
    id
    name
  }
}
    `;

/**
 * __useGetBranchesQuery__
 *
 * To run a query within a React component, call `useGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBranchesQuery(baseOptions?: Apollo.QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
      }
export function useGetBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
        }
export type GetBranchesQueryHookResult = ReturnType<typeof useGetBranchesQuery>;
export type GetBranchesLazyQueryHookResult = ReturnType<typeof useGetBranchesLazyQuery>;
export type GetBranchesQueryResult = Apollo.QueryResult<GetBranchesQuery, GetBranchesQueryVariables>;
export const GetCustomerDocument = gql`
    mutation getCustomer($phone: String!) {
  getCustomer(input: {phone: $phone}) {
    id
    title
    first_name
    last_name
    phone
    points
    joined
    nic
    dob
    branch {
      id
      name
    }
  }
}
    `;
export type GetCustomerMutationFn = Apollo.MutationFunction<GetCustomerMutation, GetCustomerMutationVariables>;

/**
 * __useGetCustomerMutation__
 *
 * To run a mutation, you first call `useGetCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCustomerMutation, { data, loading, error }] = useGetCustomerMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useGetCustomerMutation(baseOptions?: Apollo.MutationHookOptions<GetCustomerMutation, GetCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCustomerMutation, GetCustomerMutationVariables>(GetCustomerDocument, options);
      }
export type GetCustomerMutationHookResult = ReturnType<typeof useGetCustomerMutation>;
export type GetCustomerMutationResult = Apollo.MutationResult<GetCustomerMutation>;
export type GetCustomerMutationOptions = Apollo.BaseMutationOptions<GetCustomerMutation, GetCustomerMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CustomerInput!) {
  createCustomer(input: $input) {
    customer {
      id
      title
      first_name
      last_name
      phone
      points
      joined
      nic
      dob
      branch {
        id
        name
      }
    }
    errors {
      message
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const GetCustomersDocument = gql`
    query getCustomers {
  getCustomers {
    customers {
      id
      title
      first_name
      last_name
      phone
      points
      joined
      nic
      dob
      branch {
        id
        name
      }
    }
    errors {
      message
    }
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($input: Float!) {
  deleteCustomer(input: $input) {
    customer {
      id
      title
      first_name
      last_name
      phone
      points
      joined
      nic
      dob
      branch {
        id
        name
      }
    }
    errors {
      message
    }
  }
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: CustomerInput!) {
  updateCustomer(input: $input) {
    customer {
      id
      title
      first_name
      last_name
      phone
      points
      joined
      nic
      dob
      branch {
        id
        name
      }
      created_at
    }
    errors {
      message
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const PermissionsDocument = gql`
    query permissions {
  permissions {
    id
    name
    parent
  }
}
    `;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const CategoriesDocument = gql`
    query categories {
  categories {
    id
    name
    subCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const GetProductCountriesDocument = gql`
    query getProductCountries {
  getUniqueProductCountries
}
    `;

/**
 * __useGetProductCountriesQuery__
 *
 * To run a query within a React component, call `useGetProductCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCountriesQuery, GetProductCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCountriesQuery, GetProductCountriesQueryVariables>(GetProductCountriesDocument, options);
      }
export function useGetProductCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCountriesQuery, GetProductCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCountriesQuery, GetProductCountriesQueryVariables>(GetProductCountriesDocument, options);
        }
export type GetProductCountriesQueryHookResult = ReturnType<typeof useGetProductCountriesQuery>;
export type GetProductCountriesLazyQueryHookResult = ReturnType<typeof useGetProductCountriesLazyQuery>;
export type GetProductCountriesQueryResult = Apollo.QueryResult<GetProductCountriesQuery, GetProductCountriesQueryVariables>;
export const GetBrandsDocument = gql`
    query getBrands {
  getBrands {
    id
    name
    country
    categories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const GetproductsForReceiptDocument = gql`
    query getproductsForReceipt($input: String!) {
  getProductsForReceipt(input: $input) {
    id
    barcode
    discount
    discount_type
    product_size
    size
    name
    color
    code
    quantity
    price
    sub_total
    available
    in_stock
  }
}
    `;

/**
 * __useGetproductsForReceiptQuery__
 *
 * To run a query within a React component, call `useGetproductsForReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetproductsForReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetproductsForReceiptQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetproductsForReceiptQuery(baseOptions: Apollo.QueryHookOptions<GetproductsForReceiptQuery, GetproductsForReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetproductsForReceiptQuery, GetproductsForReceiptQueryVariables>(GetproductsForReceiptDocument, options);
      }
export function useGetproductsForReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetproductsForReceiptQuery, GetproductsForReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetproductsForReceiptQuery, GetproductsForReceiptQueryVariables>(GetproductsForReceiptDocument, options);
        }
export type GetproductsForReceiptQueryHookResult = ReturnType<typeof useGetproductsForReceiptQuery>;
export type GetproductsForReceiptLazyQueryHookResult = ReturnType<typeof useGetproductsForReceiptLazyQuery>;
export type GetproductsForReceiptQueryResult = Apollo.QueryResult<GetproductsForReceiptQuery, GetproductsForReceiptQueryVariables>;
export const GetProductVariationListDocument = gql`
    query getProductVariationList($input: ProductVariationListInput) {
  getProductVariationList(input: $input) {
    productVariaitons {
      product_id
      category_id
      type_id
      id
      name
      code
      size
      color
      price
    }
    hasMore
  }
}
    `;

/**
 * __useGetProductVariationListQuery__
 *
 * To run a query within a React component, call `useGetProductVariationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariationListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductVariationListQuery(baseOptions?: Apollo.QueryHookOptions<GetProductVariationListQuery, GetProductVariationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductVariationListQuery, GetProductVariationListQueryVariables>(GetProductVariationListDocument, options);
      }
export function useGetProductVariationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductVariationListQuery, GetProductVariationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductVariationListQuery, GetProductVariationListQueryVariables>(GetProductVariationListDocument, options);
        }
export type GetProductVariationListQueryHookResult = ReturnType<typeof useGetProductVariationListQuery>;
export type GetProductVariationListLazyQueryHookResult = ReturnType<typeof useGetProductVariationListLazyQuery>;
export type GetProductVariationListQueryResult = Apollo.QueryResult<GetProductVariationListQuery, GetProductVariationListQueryVariables>;
export const GetProductTypesDocument = gql`
    query getProductTypes($input: ProductVariationTypesInput) {
  getProductTypes(input: $input) {
    hasMore
    productVariationTypes {
      id
      name
      code
      size
      product {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetProductTypesQuery__
 *
 * To run a query within a React component, call `useGetProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, options);
      }
export function useGetProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, options);
        }
export type GetProductTypesQueryHookResult = ReturnType<typeof useGetProductTypesQuery>;
export type GetProductTypesLazyQueryHookResult = ReturnType<typeof useGetProductTypesLazyQuery>;
export type GetProductTypesQueryResult = Apollo.QueryResult<GetProductTypesQuery, GetProductTypesQueryVariables>;
export const GetUniqueProductSizesDocument = gql`
    query getUniqueProductSizes {
  getUniqueProductSizes
}
    `;

/**
 * __useGetUniqueProductSizesQuery__
 *
 * To run a query within a React component, call `useGetUniqueProductSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueProductSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueProductSizesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueProductSizesQuery(baseOptions?: Apollo.QueryHookOptions<GetUniqueProductSizesQuery, GetUniqueProductSizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueProductSizesQuery, GetUniqueProductSizesQueryVariables>(GetUniqueProductSizesDocument, options);
      }
export function useGetUniqueProductSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueProductSizesQuery, GetUniqueProductSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueProductSizesQuery, GetUniqueProductSizesQueryVariables>(GetUniqueProductSizesDocument, options);
        }
export type GetUniqueProductSizesQueryHookResult = ReturnType<typeof useGetUniqueProductSizesQuery>;
export type GetUniqueProductSizesLazyQueryHookResult = ReturnType<typeof useGetUniqueProductSizesLazyQuery>;
export type GetUniqueProductSizesQueryResult = Apollo.QueryResult<GetUniqueProductSizesQuery, GetUniqueProductSizesQueryVariables>;
export const GetUniqueProductColorsDocument = gql`
    query getUniqueProductColors {
  getUniqueProductColors
}
    `;

/**
 * __useGetUniqueProductColorsQuery__
 *
 * To run a query within a React component, call `useGetUniqueProductColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueProductColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueProductColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueProductColorsQuery(baseOptions?: Apollo.QueryHookOptions<GetUniqueProductColorsQuery, GetUniqueProductColorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueProductColorsQuery, GetUniqueProductColorsQueryVariables>(GetUniqueProductColorsDocument, options);
      }
export function useGetUniqueProductColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueProductColorsQuery, GetUniqueProductColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueProductColorsQuery, GetUniqueProductColorsQueryVariables>(GetUniqueProductColorsDocument, options);
        }
export type GetUniqueProductColorsQueryHookResult = ReturnType<typeof useGetUniqueProductColorsQuery>;
export type GetUniqueProductColorsLazyQueryHookResult = ReturnType<typeof useGetUniqueProductColorsLazyQuery>;
export type GetUniqueProductColorsQueryResult = Apollo.QueryResult<GetUniqueProductColorsQuery, GetUniqueProductColorsQueryVariables>;
export const GetUniqueProductCodesDocument = gql`
    query getUniqueProductCodes {
  getUniqueProductCodes
}
    `;

/**
 * __useGetUniqueProductCodesQuery__
 *
 * To run a query within a React component, call `useGetUniqueProductCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueProductCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueProductCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueProductCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetUniqueProductCodesQuery, GetUniqueProductCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueProductCodesQuery, GetUniqueProductCodesQueryVariables>(GetUniqueProductCodesDocument, options);
      }
export function useGetUniqueProductCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueProductCodesQuery, GetUniqueProductCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueProductCodesQuery, GetUniqueProductCodesQueryVariables>(GetUniqueProductCodesDocument, options);
        }
export type GetUniqueProductCodesQueryHookResult = ReturnType<typeof useGetUniqueProductCodesQuery>;
export type GetUniqueProductCodesLazyQueryHookResult = ReturnType<typeof useGetUniqueProductCodesLazyQuery>;
export type GetUniqueProductCodesQueryResult = Apollo.QueryResult<GetUniqueProductCodesQuery, GetUniqueProductCodesQueryVariables>;
export const GetUniqueProductNamesDocument = gql`
    query getUniqueProductNames {
  getUniqueProductNames
}
    `;

/**
 * __useGetUniqueProductNamesQuery__
 *
 * To run a query within a React component, call `useGetUniqueProductNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueProductNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueProductNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueProductNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetUniqueProductNamesQuery, GetUniqueProductNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueProductNamesQuery, GetUniqueProductNamesQueryVariables>(GetUniqueProductNamesDocument, options);
      }
export function useGetUniqueProductNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueProductNamesQuery, GetUniqueProductNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueProductNamesQuery, GetUniqueProductNamesQueryVariables>(GetUniqueProductNamesDocument, options);
        }
export type GetUniqueProductNamesQueryHookResult = ReturnType<typeof useGetUniqueProductNamesQuery>;
export type GetUniqueProductNamesLazyQueryHookResult = ReturnType<typeof useGetUniqueProductNamesLazyQuery>;
export type GetUniqueProductNamesQueryResult = Apollo.QueryResult<GetUniqueProductNamesQuery, GetUniqueProductNamesQueryVariables>;
export const SearchProductTypesDocument = gql`
    query searchProductTypes($input: Float!) {
  searchProductTypes(input: $input) {
    id
    name
    code
  }
}
    `;

/**
 * __useSearchProductTypesQuery__
 *
 * To run a query within a React component, call `useSearchProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchProductTypesQuery(baseOptions: Apollo.QueryHookOptions<SearchProductTypesQuery, SearchProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProductTypesQuery, SearchProductTypesQueryVariables>(SearchProductTypesDocument, options);
      }
export function useSearchProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductTypesQuery, SearchProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProductTypesQuery, SearchProductTypesQueryVariables>(SearchProductTypesDocument, options);
        }
export type SearchProductTypesQueryHookResult = ReturnType<typeof useSearchProductTypesQuery>;
export type SearchProductTypesLazyQueryHookResult = ReturnType<typeof useSearchProductTypesLazyQuery>;
export type SearchProductTypesQueryResult = Apollo.QueryResult<SearchProductTypesQuery, SearchProductTypesQueryVariables>;
export const UpdateProductVariationDocument = gql`
    mutation updateProductVariation($input: UpdateProductVarianceInput!) {
  updateProductVariation(input: $input) {
    productVariation {
      id
      barcode
      category {
        id
        name
      }
      name
      color
      price_bg
      price_ogf
      price_ogff
      price_hc
      product {
        id
        name
      }
    }
    errors {
      message
    }
  }
}
    `;
export type UpdateProductVariationMutationFn = Apollo.MutationFunction<UpdateProductVariationMutation, UpdateProductVariationMutationVariables>;

/**
 * __useUpdateProductVariationMutation__
 *
 * To run a mutation, you first call `useUpdateProductVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductVariationMutation, { data, loading, error }] = useUpdateProductVariationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductVariationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductVariationMutation, UpdateProductVariationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductVariationMutation, UpdateProductVariationMutationVariables>(UpdateProductVariationDocument, options);
      }
export type UpdateProductVariationMutationHookResult = ReturnType<typeof useUpdateProductVariationMutation>;
export type UpdateProductVariationMutationResult = Apollo.MutationResult<UpdateProductVariationMutation>;
export type UpdateProductVariationMutationOptions = Apollo.BaseMutationOptions<UpdateProductVariationMutation, UpdateProductVariationMutationVariables>;
export const UpdateBrandDocument = gql`
    mutation updateBrand($input: UpdateBrandInput!) {
  updateBrand(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type UpdateBrandMutationFn = Apollo.MutationFunction<UpdateBrandMutation, UpdateBrandMutationVariables>;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandMutation, UpdateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(UpdateBrandDocument, options);
      }
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
export type UpdateBrandMutationResult = Apollo.MutationResult<UpdateBrandMutation>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<UpdateBrandMutation, UpdateBrandMutationVariables>;
export const UpdateProductTypeDocument = gql`
    mutation updateProductType($input: UpdateProductInput!) {
  updateProductType(input: $input) {
    productVariationType {
      id
      name
      code
      size
      product {
        id
        name
      }
    }
    errors {
      message
    }
  }
}
    `;
export type UpdateProductTypeMutationFn = Apollo.MutationFunction<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>;

/**
 * __useUpdateProductTypeMutation__
 *
 * To run a mutation, you first call `useUpdateProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductTypeMutation, { data, loading, error }] = useUpdateProductTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>(UpdateProductTypeDocument, options);
      }
export type UpdateProductTypeMutationHookResult = ReturnType<typeof useUpdateProductTypeMutation>;
export type UpdateProductTypeMutationResult = Apollo.MutationResult<UpdateProductTypeMutation>;
export type UpdateProductTypeMutationOptions = Apollo.BaseMutationOptions<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($input: Float!) {
  deleteCategory(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const DeleteBrandDocument = gql`
    mutation deleteBrand($input: Float!) {
  deleteBrand(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type DeleteBrandMutationFn = Apollo.MutationFunction<DeleteBrandMutation, DeleteBrandMutationVariables>;

/**
 * __useDeleteBrandMutation__
 *
 * To run a mutation, you first call `useDeleteBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandMutation, { data, loading, error }] = useDeleteBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBrandMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBrandMutation, DeleteBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBrandMutation, DeleteBrandMutationVariables>(DeleteBrandDocument, options);
      }
export type DeleteBrandMutationHookResult = ReturnType<typeof useDeleteBrandMutation>;
export type DeleteBrandMutationResult = Apollo.MutationResult<DeleteBrandMutation>;
export type DeleteBrandMutationOptions = Apollo.BaseMutationOptions<DeleteBrandMutation, DeleteBrandMutationVariables>;
export const DeleteProductTypeDocument = gql`
    mutation deleteProductType($input: Float!) {
  deleteProductType(input: $input) {
    productVairationType {
      id
      name
      code
      size
      product {
        id
        name
      }
    }
    errors {
      message
    }
  }
}
    `;
export type DeleteProductTypeMutationFn = Apollo.MutationFunction<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>;

/**
 * __useDeleteProductTypeMutation__
 *
 * To run a mutation, you first call `useDeleteProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductTypeMutation, { data, loading, error }] = useDeleteProductTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>(DeleteProductTypeDocument, options);
      }
export type DeleteProductTypeMutationHookResult = ReturnType<typeof useDeleteProductTypeMutation>;
export type DeleteProductTypeMutationResult = Apollo.MutationResult<DeleteProductTypeMutation>;
export type DeleteProductTypeMutationOptions = Apollo.BaseMutationOptions<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>;
export const DeleteProductVarianceDocument = gql`
    mutation deleteProductVariance($input: Float!) {
  deleteProductVariance(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type DeleteProductVarianceMutationFn = Apollo.MutationFunction<DeleteProductVarianceMutation, DeleteProductVarianceMutationVariables>;

/**
 * __useDeleteProductVarianceMutation__
 *
 * To run a mutation, you first call `useDeleteProductVarianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductVarianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductVarianceMutation, { data, loading, error }] = useDeleteProductVarianceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductVarianceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductVarianceMutation, DeleteProductVarianceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductVarianceMutation, DeleteProductVarianceMutationVariables>(DeleteProductVarianceDocument, options);
      }
export type DeleteProductVarianceMutationHookResult = ReturnType<typeof useDeleteProductVarianceMutation>;
export type DeleteProductVarianceMutationResult = Apollo.MutationResult<DeleteProductVarianceMutation>;
export type DeleteProductVarianceMutationOptions = Apollo.BaseMutationOptions<DeleteProductVarianceMutation, DeleteProductVarianceMutationVariables>;
export const GetOrderDocument = gql`
    mutation GetOrder($input: GetOrderInput!) {
  getOrder(input: $input) {
    orderPayment {
      amount_paid
      points
    }
    order {
      id
      order_number
      date
      branch {
        id
        name
      }
    }
    points
    hcmLoyality
    hcmOrderNumber
    user
    items {
      id
      name
      stockDate
      brand
      code
      quantity
      price
      discount
      discountType
      addToStock
      productVariationId
    }
    customer {
      title
      last_name
      phone
      points
    }
    paymentMethods {
      type
      amount
    }
  }
}
    `;
export type GetOrderMutationFn = Apollo.MutationFunction<GetOrderMutation, GetOrderMutationVariables>;

/**
 * __useGetOrderMutation__
 *
 * To run a mutation, you first call `useGetOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrderMutation, { data, loading, error }] = useGetOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderMutation(baseOptions?: Apollo.MutationHookOptions<GetOrderMutation, GetOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetOrderMutation, GetOrderMutationVariables>(GetOrderDocument, options);
      }
export type GetOrderMutationHookResult = ReturnType<typeof useGetOrderMutation>;
export type GetOrderMutationResult = Apollo.MutationResult<GetOrderMutation>;
export type GetOrderMutationOptions = Apollo.BaseMutationOptions<GetOrderMutation, GetOrderMutationVariables>;
export const GetOrderNumberDocument = gql`
    query getOrderNumber {
  getOrderNumber
}
    `;

/**
 * __useGetOrderNumberQuery__
 *
 * To run a query within a React component, call `useGetOrderNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderNumberQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderNumberQuery, GetOrderNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderNumberQuery, GetOrderNumberQueryVariables>(GetOrderNumberDocument, options);
      }
export function useGetOrderNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderNumberQuery, GetOrderNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderNumberQuery, GetOrderNumberQueryVariables>(GetOrderNumberDocument, options);
        }
export type GetOrderNumberQueryHookResult = ReturnType<typeof useGetOrderNumberQuery>;
export type GetOrderNumberLazyQueryHookResult = ReturnType<typeof useGetOrderNumberLazyQuery>;
export type GetOrderNumberQueryResult = Apollo.QueryResult<GetOrderNumberQuery, GetOrderNumberQueryVariables>;
export const GetReturnReceiptNumberDocument = gql`
    query getReturnReceiptNumber {
  getReturnReceiptNumber
}
    `;

/**
 * __useGetReturnReceiptNumberQuery__
 *
 * To run a query within a React component, call `useGetReturnReceiptNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnReceiptNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnReceiptNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReturnReceiptNumberQuery(baseOptions?: Apollo.QueryHookOptions<GetReturnReceiptNumberQuery, GetReturnReceiptNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnReceiptNumberQuery, GetReturnReceiptNumberQueryVariables>(GetReturnReceiptNumberDocument, options);
      }
export function useGetReturnReceiptNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnReceiptNumberQuery, GetReturnReceiptNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnReceiptNumberQuery, GetReturnReceiptNumberQueryVariables>(GetReturnReceiptNumberDocument, options);
        }
export type GetReturnReceiptNumberQueryHookResult = ReturnType<typeof useGetReturnReceiptNumberQuery>;
export type GetReturnReceiptNumberLazyQueryHookResult = ReturnType<typeof useGetReturnReceiptNumberLazyQuery>;
export type GetReturnReceiptNumberQueryResult = Apollo.QueryResult<GetReturnReceiptNumberQuery, GetReturnReceiptNumberQueryVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    orderNumber
    errors {
      message
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const OrderPaymentTypesDocument = gql`
    query orderPaymentTypes {
  orderPaymentTypes {
    id
    name
  }
}
    `;

/**
 * __useOrderPaymentTypesQuery__
 *
 * To run a query within a React component, call `useOrderPaymentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPaymentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderPaymentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderPaymentTypesQuery(baseOptions?: Apollo.QueryHookOptions<OrderPaymentTypesQuery, OrderPaymentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderPaymentTypesQuery, OrderPaymentTypesQueryVariables>(OrderPaymentTypesDocument, options);
      }
export function useOrderPaymentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderPaymentTypesQuery, OrderPaymentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderPaymentTypesQuery, OrderPaymentTypesQueryVariables>(OrderPaymentTypesDocument, options);
        }
export type OrderPaymentTypesQueryHookResult = ReturnType<typeof useOrderPaymentTypesQuery>;
export type OrderPaymentTypesLazyQueryHookResult = ReturnType<typeof useOrderPaymentTypesLazyQuery>;
export type OrderPaymentTypesQueryResult = Apollo.QueryResult<OrderPaymentTypesQuery, OrderPaymentTypesQueryVariables>;
export const GetReturnedItemsDocument = gql`
    query getReturnedItems($input: Float!) {
  getReturnedItems(input: $input) {
    id
    quantity
  }
}
    `;

/**
 * __useGetReturnedItemsQuery__
 *
 * To run a query within a React component, call `useGetReturnedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnedItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReturnedItemsQuery(baseOptions: Apollo.QueryHookOptions<GetReturnedItemsQuery, GetReturnedItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnedItemsQuery, GetReturnedItemsQueryVariables>(GetReturnedItemsDocument, options);
      }
export function useGetReturnedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnedItemsQuery, GetReturnedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnedItemsQuery, GetReturnedItemsQueryVariables>(GetReturnedItemsDocument, options);
        }
export type GetReturnedItemsQueryHookResult = ReturnType<typeof useGetReturnedItemsQuery>;
export type GetReturnedItemsLazyQueryHookResult = ReturnType<typeof useGetReturnedItemsLazyQuery>;
export type GetReturnedItemsQueryResult = Apollo.QueryResult<GetReturnedItemsQuery, GetReturnedItemsQueryVariables>;
export const GetOrderListDocument = gql`
    query getOrderList($input: OrderListInput!) {
  getOrderList(input: $input) {
    orderList {
      id
      orderNumber
      date
      void
      cashier
      total
    }
    hasMore
  }
}
    `;

/**
 * __useGetOrderListQuery__
 *
 * To run a query within a React component, call `useGetOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderListQuery(baseOptions: Apollo.QueryHookOptions<GetOrderListQuery, GetOrderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderListQuery, GetOrderListQueryVariables>(GetOrderListDocument, options);
      }
export function useGetOrderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderListQuery, GetOrderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderListQuery, GetOrderListQueryVariables>(GetOrderListDocument, options);
        }
export type GetOrderListQueryHookResult = ReturnType<typeof useGetOrderListQuery>;
export type GetOrderListLazyQueryHookResult = ReturnType<typeof useGetOrderListLazyQuery>;
export type GetOrderListQueryResult = Apollo.QueryResult<GetOrderListQuery, GetOrderListQueryVariables>;
export const VoidOrderDocument = gql`
    mutation voidOrder($id: Float!) {
  voidOrder(id: $id) {
    id
    errors {
      message
    }
  }
}
    `;
export type VoidOrderMutationFn = Apollo.MutationFunction<VoidOrderMutation, VoidOrderMutationVariables>;

/**
 * __useVoidOrderMutation__
 *
 * To run a mutation, you first call `useVoidOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidOrderMutation, { data, loading, error }] = useVoidOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVoidOrderMutation(baseOptions?: Apollo.MutationHookOptions<VoidOrderMutation, VoidOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidOrderMutation, VoidOrderMutationVariables>(VoidOrderDocument, options);
      }
export type VoidOrderMutationHookResult = ReturnType<typeof useVoidOrderMutation>;
export type VoidOrderMutationResult = Apollo.MutationResult<VoidOrderMutation>;
export type VoidOrderMutationOptions = Apollo.BaseMutationOptions<VoidOrderMutation, VoidOrderMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($id: Float!) {
  deleteOrder(id: $id) {
    order {
      id
    }
    errors {
      message
    }
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const CreateExchangeReceiptDocument = gql`
    mutation createExchangeReceipt($input: createExchangeReceiptInput!) {
  createExchangeReceipt(input: $input) {
    returnReceiptNumber
  }
}
    `;
export type CreateExchangeReceiptMutationFn = Apollo.MutationFunction<CreateExchangeReceiptMutation, CreateExchangeReceiptMutationVariables>;

/**
 * __useCreateExchangeReceiptMutation__
 *
 * To run a mutation, you first call `useCreateExchangeReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExchangeReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExchangeReceiptMutation, { data, loading, error }] = useCreateExchangeReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExchangeReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateExchangeReceiptMutation, CreateExchangeReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExchangeReceiptMutation, CreateExchangeReceiptMutationVariables>(CreateExchangeReceiptDocument, options);
      }
export type CreateExchangeReceiptMutationHookResult = ReturnType<typeof useCreateExchangeReceiptMutation>;
export type CreateExchangeReceiptMutationResult = Apollo.MutationResult<CreateExchangeReceiptMutation>;
export type CreateExchangeReceiptMutationOptions = Apollo.BaseMutationOptions<CreateExchangeReceiptMutation, CreateExchangeReceiptMutationVariables>;
export const GetExchangeReceiptsDocument = gql`
    query getExchangeReceipts {
  getExchangeReceipts {
    id
    return_receipt_number
    date
    associated {
      order_number
    }
  }
}
    `;

/**
 * __useGetExchangeReceiptsQuery__
 *
 * To run a query within a React component, call `useGetExchangeReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeReceiptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExchangeReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<GetExchangeReceiptsQuery, GetExchangeReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExchangeReceiptsQuery, GetExchangeReceiptsQueryVariables>(GetExchangeReceiptsDocument, options);
      }
export function useGetExchangeReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeReceiptsQuery, GetExchangeReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExchangeReceiptsQuery, GetExchangeReceiptsQueryVariables>(GetExchangeReceiptsDocument, options);
        }
export type GetExchangeReceiptsQueryHookResult = ReturnType<typeof useGetExchangeReceiptsQuery>;
export type GetExchangeReceiptsLazyQueryHookResult = ReturnType<typeof useGetExchangeReceiptsLazyQuery>;
export type GetExchangeReceiptsQueryResult = Apollo.QueryResult<GetExchangeReceiptsQuery, GetExchangeReceiptsQueryVariables>;
export const GetExchangeReceiptInfoDocument = gql`
    query getExchangeReceiptInfo($input: Float!) {
  getExchangeReceiptInfo(input: $input) {
    returnId
    branch
    orderNumber
    storeCredit
    createdAt
    date
    items {
      id
      name
      quantity
      price
      discount
      discountType
    }
  }
}
    `;

/**
 * __useGetExchangeReceiptInfoQuery__
 *
 * To run a query within a React component, call `useGetExchangeReceiptInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeReceiptInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeReceiptInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetExchangeReceiptInfoQuery(baseOptions: Apollo.QueryHookOptions<GetExchangeReceiptInfoQuery, GetExchangeReceiptInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExchangeReceiptInfoQuery, GetExchangeReceiptInfoQueryVariables>(GetExchangeReceiptInfoDocument, options);
      }
export function useGetExchangeReceiptInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeReceiptInfoQuery, GetExchangeReceiptInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExchangeReceiptInfoQuery, GetExchangeReceiptInfoQueryVariables>(GetExchangeReceiptInfoDocument, options);
        }
export type GetExchangeReceiptInfoQueryHookResult = ReturnType<typeof useGetExchangeReceiptInfoQuery>;
export type GetExchangeReceiptInfoLazyQueryHookResult = ReturnType<typeof useGetExchangeReceiptInfoLazyQuery>;
export type GetExchangeReceiptInfoQueryResult = Apollo.QueryResult<GetExchangeReceiptInfoQuery, GetExchangeReceiptInfoQueryVariables>;
export const GetReturnedOrderDocument = gql`
    query getReturnedOrder($input: Float!) {
  getReturnedOrder(input: $input) {
    orderPayment {
      amount_paid
      points
    }
    order {
      id
      order_number
      date
    }
    points
    hcmLoyality
    user
    items {
      id
      name
      quantity
      price
      discount
      discountType
      addToStock
      productVariationId
    }
    customer {
      title
      last_name
    }
    returnedOrderNumber
    returnedReceiptAmount
    paymentMethods {
      type
      amount
    }
  }
}
    `;

/**
 * __useGetReturnedOrderQuery__
 *
 * To run a query within a React component, call `useGetReturnedOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnedOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnedOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReturnedOrderQuery(baseOptions: Apollo.QueryHookOptions<GetReturnedOrderQuery, GetReturnedOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnedOrderQuery, GetReturnedOrderQueryVariables>(GetReturnedOrderDocument, options);
      }
export function useGetReturnedOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnedOrderQuery, GetReturnedOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnedOrderQuery, GetReturnedOrderQueryVariables>(GetReturnedOrderDocument, options);
        }
export type GetReturnedOrderQueryHookResult = ReturnType<typeof useGetReturnedOrderQuery>;
export type GetReturnedOrderLazyQueryHookResult = ReturnType<typeof useGetReturnedOrderLazyQuery>;
export type GetReturnedOrderQueryResult = Apollo.QueryResult<GetReturnedOrderQuery, GetReturnedOrderQueryVariables>;
export const CreateRoleDocument = gql`
    mutation createRole($input: RoleInput!) {
  createRole(input: $input) {
    role {
      id
      name
    }
    errors {
      message
    }
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const RolesDocument = gql`
    query roles {
  roles {
    id
    name
    permissions {
      id
      name
      parent
    }
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const AssignPermissionsToRoleDocument = gql`
    mutation assignPermissionsToRole($id: Float!, $permissions: [Float!]!) {
  assignPermissionsToRole(id: $id, permissions: $permissions) {
    role {
      id
    }
    errors {
      message
    }
  }
}
    `;
export type AssignPermissionsToRoleMutationFn = Apollo.MutationFunction<AssignPermissionsToRoleMutation, AssignPermissionsToRoleMutationVariables>;

/**
 * __useAssignPermissionsToRoleMutation__
 *
 * To run a mutation, you first call `useAssignPermissionsToRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPermissionsToRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPermissionsToRoleMutation, { data, loading, error }] = useAssignPermissionsToRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useAssignPermissionsToRoleMutation(baseOptions?: Apollo.MutationHookOptions<AssignPermissionsToRoleMutation, AssignPermissionsToRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignPermissionsToRoleMutation, AssignPermissionsToRoleMutationVariables>(AssignPermissionsToRoleDocument, options);
      }
export type AssignPermissionsToRoleMutationHookResult = ReturnType<typeof useAssignPermissionsToRoleMutation>;
export type AssignPermissionsToRoleMutationResult = Apollo.MutationResult<AssignPermissionsToRoleMutation>;
export type AssignPermissionsToRoleMutationOptions = Apollo.BaseMutationOptions<AssignPermissionsToRoleMutation, AssignPermissionsToRoleMutationVariables>;
export const EditRoleDocument = gql`
    mutation editRole($id: Float!, $name: String!) {
  editRole(id: $id, name: $name) {
    role {
      id
      name
    }
    errors {
      message
    }
  }
}
    `;
export type EditRoleMutationFn = Apollo.MutationFunction<EditRoleMutation, EditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleMutation, EditRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, options);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<EditRoleMutation, EditRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($id: Float!) {
  deleteRole(id: $id) {
    id
    errors {
      message
    }
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const GetSalesDocument = gql`
    query getSales($input: GetSalesInput!) {
  getSales(input: $input) {
    hasMore
    sales {
      id
      orderNumber
      brandName
      country
      productName
      code
      color
      size
      date
      time
      date_time
      orderNumber
      supplierId
      purchasePrice
      sellingPrice
      discount
      totalQuantity
      returnQuantity
      paymentMethod
      isReturn
      associatedOrderNumber
      return
    }
    totalSale
    totalProfit
    itemsSold
    errors {
      message
    }
  }
}
    `;

/**
 * __useGetSalesQuery__
 *
 * To run a query within a React component, call `useGetSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSalesQuery(baseOptions: Apollo.QueryHookOptions<GetSalesQuery, GetSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesQuery, GetSalesQueryVariables>(GetSalesDocument, options);
      }
export function useGetSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesQuery, GetSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesQuery, GetSalesQueryVariables>(GetSalesDocument, options);
        }
export type GetSalesQueryHookResult = ReturnType<typeof useGetSalesQuery>;
export type GetSalesLazyQueryHookResult = ReturnType<typeof useGetSalesLazyQuery>;
export type GetSalesQueryResult = Apollo.QueryResult<GetSalesQuery, GetSalesQueryVariables>;
export const GetSalesAchievedDocument = gql`
    query getSalesAchieved($input: GetSalesTargetInput!) {
  getSalesAchieved(input: $input) {
    bgSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    ogfSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    ogffSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    hcSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    hasMore
    errors {
      message
    }
  }
}
    `;

/**
 * __useGetSalesAchievedQuery__
 *
 * To run a query within a React component, call `useGetSalesAchievedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesAchievedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesAchievedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSalesAchievedQuery(baseOptions: Apollo.QueryHookOptions<GetSalesAchievedQuery, GetSalesAchievedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesAchievedQuery, GetSalesAchievedQueryVariables>(GetSalesAchievedDocument, options);
      }
export function useGetSalesAchievedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesAchievedQuery, GetSalesAchievedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesAchievedQuery, GetSalesAchievedQueryVariables>(GetSalesAchievedDocument, options);
        }
export type GetSalesAchievedQueryHookResult = ReturnType<typeof useGetSalesAchievedQuery>;
export type GetSalesAchievedLazyQueryHookResult = ReturnType<typeof useGetSalesAchievedLazyQuery>;
export type GetSalesAchievedQueryResult = Apollo.QueryResult<GetSalesAchievedQuery, GetSalesAchievedQueryVariables>;
export const GetTestSalesReportDocument = gql`
    query getTestSalesReport($input: GetSalesInput!) {
  getTestSalesReport(input: $input) {
    sales {
      id
      orderNumber
      brandName
      country
      productName
      code
      color
      size
      date
      date_time
      time
      paymentMethod
      sale
      profit
      isReturn
    }
    hasMore
    totalSale
    totalProfit
    errors {
      message
    }
  }
}
    `;

/**
 * __useGetTestSalesReportQuery__
 *
 * To run a query within a React component, call `useGetTestSalesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestSalesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestSalesReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTestSalesReportQuery(baseOptions: Apollo.QueryHookOptions<GetTestSalesReportQuery, GetTestSalesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestSalesReportQuery, GetTestSalesReportQueryVariables>(GetTestSalesReportDocument, options);
      }
export function useGetTestSalesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestSalesReportQuery, GetTestSalesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestSalesReportQuery, GetTestSalesReportQueryVariables>(GetTestSalesReportDocument, options);
        }
export type GetTestSalesReportQueryHookResult = ReturnType<typeof useGetTestSalesReportQuery>;
export type GetTestSalesReportLazyQueryHookResult = ReturnType<typeof useGetTestSalesReportLazyQuery>;
export type GetTestSalesReportQueryResult = Apollo.QueryResult<GetTestSalesReportQuery, GetTestSalesReportQueryVariables>;
export const CreateSalesTargetDocument = gql`
    mutation createSalesTarget($input: SalesTargetInput!) {
  createSalesTarget(input: $input) {
    id
    year
    month
    target
    created_at
  }
}
    `;
export type CreateSalesTargetMutationFn = Apollo.MutationFunction<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>;

/**
 * __useCreateSalesTargetMutation__
 *
 * To run a mutation, you first call `useCreateSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesTargetMutation, { data, loading, error }] = useCreateSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesTargetMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>(CreateSalesTargetDocument, options);
      }
export type CreateSalesTargetMutationHookResult = ReturnType<typeof useCreateSalesTargetMutation>;
export type CreateSalesTargetMutationResult = Apollo.MutationResult<CreateSalesTargetMutation>;
export type CreateSalesTargetMutationOptions = Apollo.BaseMutationOptions<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>;
export const UpdateSalesTargetDocument = gql`
    mutation updateSalesTarget($input: SalesTargetInput!) {
  updateSalesTarget(input: $input) {
    bgSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    ogfSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    ogffSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    hcSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    errors {
      message
    }
  }
}
    `;
export type UpdateSalesTargetMutationFn = Apollo.MutationFunction<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>;

/**
 * __useUpdateSalesTargetMutation__
 *
 * To run a mutation, you first call `useUpdateSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesTargetMutation, { data, loading, error }] = useUpdateSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesTargetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>(UpdateSalesTargetDocument, options);
      }
export type UpdateSalesTargetMutationHookResult = ReturnType<typeof useUpdateSalesTargetMutation>;
export type UpdateSalesTargetMutationResult = Apollo.MutationResult<UpdateSalesTargetMutation>;
export type UpdateSalesTargetMutationOptions = Apollo.BaseMutationOptions<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>;
export const DeleteSalesTargetDocument = gql`
    mutation deleteSalesTarget($input: Int!) {
  deleteSalesTarget(input: $input) {
    bgSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    ogfSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    ogffSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    hcSaleAchieved {
      id
      year
      month
      target
      achieved
      achievedPercentage
    }
    errors {
      message
    }
  }
}
    `;
export type DeleteSalesTargetMutationFn = Apollo.MutationFunction<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>;

/**
 * __useDeleteSalesTargetMutation__
 *
 * To run a mutation, you first call `useDeleteSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesTargetMutation, { data, loading, error }] = useDeleteSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSalesTargetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>(DeleteSalesTargetDocument, options);
      }
export type DeleteSalesTargetMutationHookResult = ReturnType<typeof useDeleteSalesTargetMutation>;
export type DeleteSalesTargetMutationResult = Apollo.MutationResult<DeleteSalesTargetMutation>;
export type DeleteSalesTargetMutationOptions = Apollo.BaseMutationOptions<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>;
export const GetLastSevenDaysSalesDocument = gql`
    query getLastSevenDaysSales($input: GetSalesInput!) {
  getLastSevenDaysSales(input: $input) {
    labels
    data
  }
}
    `;

/**
 * __useGetLastSevenDaysSalesQuery__
 *
 * To run a query within a React component, call `useGetLastSevenDaysSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSevenDaysSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSevenDaysSalesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLastSevenDaysSalesQuery(baseOptions: Apollo.QueryHookOptions<GetLastSevenDaysSalesQuery, GetLastSevenDaysSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastSevenDaysSalesQuery, GetLastSevenDaysSalesQueryVariables>(GetLastSevenDaysSalesDocument, options);
      }
export function useGetLastSevenDaysSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastSevenDaysSalesQuery, GetLastSevenDaysSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastSevenDaysSalesQuery, GetLastSevenDaysSalesQueryVariables>(GetLastSevenDaysSalesDocument, options);
        }
export type GetLastSevenDaysSalesQueryHookResult = ReturnType<typeof useGetLastSevenDaysSalesQuery>;
export type GetLastSevenDaysSalesLazyQueryHookResult = ReturnType<typeof useGetLastSevenDaysSalesLazyQuery>;
export type GetLastSevenDaysSalesQueryResult = Apollo.QueryResult<GetLastSevenDaysSalesQuery, GetLastSevenDaysSalesQueryVariables>;
export const ExportHcmSalesDocument = gql`
    mutation exportHCMSales($input: SalesExportInput!) {
  downloadUsersExcel(input: $input)
}
    `;
export type ExportHcmSalesMutationFn = Apollo.MutationFunction<ExportHcmSalesMutation, ExportHcmSalesMutationVariables>;

/**
 * __useExportHcmSalesMutation__
 *
 * To run a mutation, you first call `useExportHcmSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportHcmSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportHcmSalesMutation, { data, loading, error }] = useExportHcmSalesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportHcmSalesMutation(baseOptions?: Apollo.MutationHookOptions<ExportHcmSalesMutation, ExportHcmSalesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportHcmSalesMutation, ExportHcmSalesMutationVariables>(ExportHcmSalesDocument, options);
      }
export type ExportHcmSalesMutationHookResult = ReturnType<typeof useExportHcmSalesMutation>;
export type ExportHcmSalesMutationResult = Apollo.MutationResult<ExportHcmSalesMutation>;
export type ExportHcmSalesMutationOptions = Apollo.BaseMutationOptions<ExportHcmSalesMutation, ExportHcmSalesMutationVariables>;
export const GetRolesDocument = gql`
    query getRoles {
  roles {
    id
    name
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const CreateStockDocument = gql`
    mutation createStock($input: CreateStockInput!) {
  createStock(input: $input)
}
    `;
export type CreateStockMutationFn = Apollo.MutationFunction<CreateStockMutation, CreateStockMutationVariables>;

/**
 * __useCreateStockMutation__
 *
 * To run a mutation, you first call `useCreateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockMutation, { data, loading, error }] = useCreateStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockMutation, CreateStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStockMutation, CreateStockMutationVariables>(CreateStockDocument, options);
      }
export type CreateStockMutationHookResult = ReturnType<typeof useCreateStockMutation>;
export type CreateStockMutationResult = Apollo.MutationResult<CreateStockMutation>;
export type CreateStockMutationOptions = Apollo.BaseMutationOptions<CreateStockMutation, CreateStockMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($input: CategoryInput!) {
  createCategory(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($input: CategoryInput!) {
  updateCategory(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const CreateBrandDocument = gql`
    mutation createBrand($input: CreateBrandInput!) {
  createBrand(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, options);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const CreateProductTypeDocument = gql`
    mutation createProductType($input: CreateProductTypeInput!) {
  createProductType(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type CreateProductTypeMutationFn = Apollo.MutationFunction<CreateProductTypeMutation, CreateProductTypeMutationVariables>;

/**
 * __useCreateProductTypeMutation__
 *
 * To run a mutation, you first call `useCreateProductTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductTypeMutation, { data, loading, error }] = useCreateProductTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductTypeMutation, CreateProductTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductTypeMutation, CreateProductTypeMutationVariables>(CreateProductTypeDocument, options);
      }
export type CreateProductTypeMutationHookResult = ReturnType<typeof useCreateProductTypeMutation>;
export type CreateProductTypeMutationResult = Apollo.MutationResult<CreateProductTypeMutation>;
export type CreateProductTypeMutationOptions = Apollo.BaseMutationOptions<CreateProductTypeMutation, CreateProductTypeMutationVariables>;
export const CreateProductVariationDocument = gql`
    mutation createProductVariation($input: CreateProductVarianceInput!) {
  createProductVariance(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type CreateProductVariationMutationFn = Apollo.MutationFunction<CreateProductVariationMutation, CreateProductVariationMutationVariables>;

/**
 * __useCreateProductVariationMutation__
 *
 * To run a mutation, you first call `useCreateProductVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductVariationMutation, { data, loading, error }] = useCreateProductVariationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductVariationMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductVariationMutation, CreateProductVariationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductVariationMutation, CreateProductVariationMutationVariables>(CreateProductVariationDocument, options);
      }
export type CreateProductVariationMutationHookResult = ReturnType<typeof useCreateProductVariationMutation>;
export type CreateProductVariationMutationResult = Apollo.MutationResult<CreateProductVariationMutation>;
export type CreateProductVariationMutationOptions = Apollo.BaseMutationOptions<CreateProductVariationMutation, CreateProductVariationMutationVariables>;
export const AvaiableStockDocument = gql`
    query avaiableStock($input: StockAvailableInput) {
  getAvaiableStock(input: $input) {
    bgAvailable {
      id
      pv_id
      category
      brand
      name
      code
      available
      in_stock
    }
    ogfAvailable {
      id
      pv_id
      category
      brand
      name
      code
      available
      in_stock
    }
    ogffAvailable {
      id
      pv_id
      category
      brand
      name
      code
      available
      in_stock
    }
    hcAvailable {
      id
      pv_id
      category
      brand
      name
      code
      available
      in_stock
    }
    hasMore
  }
}
    `;

/**
 * __useAvaiableStockQuery__
 *
 * To run a query within a React component, call `useAvaiableStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvaiableStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvaiableStockQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvaiableStockQuery(baseOptions?: Apollo.QueryHookOptions<AvaiableStockQuery, AvaiableStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvaiableStockQuery, AvaiableStockQueryVariables>(AvaiableStockDocument, options);
      }
export function useAvaiableStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvaiableStockQuery, AvaiableStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvaiableStockQuery, AvaiableStockQueryVariables>(AvaiableStockDocument, options);
        }
export type AvaiableStockQueryHookResult = ReturnType<typeof useAvaiableStockQuery>;
export type AvaiableStockLazyQueryHookResult = ReturnType<typeof useAvaiableStockLazyQuery>;
export type AvaiableStockQueryResult = Apollo.QueryResult<AvaiableStockQuery, AvaiableStockQueryVariables>;
export const GetStockValueDocument = gql`
    query getStockValue($input: StockValueInput!) {
  getStockValue(input: $input) {
    bgValue {
      id
      country
      supplier
      brand
      code
      name
      color
      quantity
      purchase_price
      total
    }
    ogfValue {
      id
      country
      supplier
      brand
      code
      name
      color
      quantity
      purchase_price
      total
      total
    }
    ogffValue {
      id
      country
      supplier
      brand
      code
      name
      color
      quantity
      purchase_price
      total
      total
    }
    hcValue {
      id
      country
      supplier
      brand
      code
      name
      color
      quantity
      purchase_price
      total
      total
    }
    total
    quantity
    hasMore
  }
}
    `;

/**
 * __useGetStockValueQuery__
 *
 * To run a query within a React component, call `useGetStockValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockValueQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStockValueQuery(baseOptions: Apollo.QueryHookOptions<GetStockValueQuery, GetStockValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockValueQuery, GetStockValueQueryVariables>(GetStockValueDocument, options);
      }
export function useGetStockValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockValueQuery, GetStockValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockValueQuery, GetStockValueQueryVariables>(GetStockValueDocument, options);
        }
export type GetStockValueQueryHookResult = ReturnType<typeof useGetStockValueQuery>;
export type GetStockValueLazyQueryHookResult = ReturnType<typeof useGetStockValueLazyQuery>;
export type GetStockValueQueryResult = Apollo.QueryResult<GetStockValueQuery, GetStockValueQueryVariables>;
export const GetStockVarianceDocument = gql`
    query getStockVariance($input: StockVarianceInput!) {
  getStockVariance(input: $input) {
    stockVariance {
      id
      code
      name
      color
      size
      spv_id
      quantity
      purchase_price
      total
      supplier_id
      supplier_name
      branch
      date
    }
    hasMore
  }
}
    `;

/**
 * __useGetStockVarianceQuery__
 *
 * To run a query within a React component, call `useGetStockVarianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockVarianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockVarianceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStockVarianceQuery(baseOptions: Apollo.QueryHookOptions<GetStockVarianceQuery, GetStockVarianceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockVarianceQuery, GetStockVarianceQueryVariables>(GetStockVarianceDocument, options);
      }
export function useGetStockVarianceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockVarianceQuery, GetStockVarianceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockVarianceQuery, GetStockVarianceQueryVariables>(GetStockVarianceDocument, options);
        }
export type GetStockVarianceQueryHookResult = ReturnType<typeof useGetStockVarianceQuery>;
export type GetStockVarianceLazyQueryHookResult = ReturnType<typeof useGetStockVarianceLazyQuery>;
export type GetStockVarianceQueryResult = Apollo.QueryResult<GetStockVarianceQuery, GetStockVarianceQueryVariables>;
export const GetReturnableStocksDocument = gql`
    query getReturnableStocks($input: Float!) {
  getReturnableStocks(input: $input) {
    stock_id
    date
    product_variation_id
    name
    quantity
    returnable
  }
}
    `;

/**
 * __useGetReturnableStocksQuery__
 *
 * To run a query within a React component, call `useGetReturnableStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnableStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnableStocksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReturnableStocksQuery(baseOptions: Apollo.QueryHookOptions<GetReturnableStocksQuery, GetReturnableStocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnableStocksQuery, GetReturnableStocksQueryVariables>(GetReturnableStocksDocument, options);
      }
export function useGetReturnableStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnableStocksQuery, GetReturnableStocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnableStocksQuery, GetReturnableStocksQueryVariables>(GetReturnableStocksDocument, options);
        }
export type GetReturnableStocksQueryHookResult = ReturnType<typeof useGetReturnableStocksQuery>;
export type GetReturnableStocksLazyQueryHookResult = ReturnType<typeof useGetReturnableStocksLazyQuery>;
export type GetReturnableStocksQueryResult = Apollo.QueryResult<GetReturnableStocksQuery, GetReturnableStocksQueryVariables>;
export const GetStockInvoicesDocument = gql`
    query getStockInvoices($input: StockInvoiceInput) {
  getStockInvoices(input: $input) {
    bgInvoice {
      id
      stock_id
      date
      supplier
      category
      code
      color
      quantity
    }
    ogfInvoice {
      id
      stock_id
      date
      supplier
      category
      code
      color
      quantity
    }
    ogffInvoice {
      id
      stock_id
      date
      supplier
      category
      code
      color
      quantity
    }
    hcInvoice {
      id
      stock_id
      date
      supplier
      category
      code
      color
      quantity
    }
    hasMore
  }
}
    `;

/**
 * __useGetStockInvoicesQuery__
 *
 * To run a query within a React component, call `useGetStockInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockInvoicesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStockInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetStockInvoicesQuery, GetStockInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockInvoicesQuery, GetStockInvoicesQueryVariables>(GetStockInvoicesDocument, options);
      }
export function useGetStockInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockInvoicesQuery, GetStockInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockInvoicesQuery, GetStockInvoicesQueryVariables>(GetStockInvoicesDocument, options);
        }
export type GetStockInvoicesQueryHookResult = ReturnType<typeof useGetStockInvoicesQuery>;
export type GetStockInvoicesLazyQueryHookResult = ReturnType<typeof useGetStockInvoicesLazyQuery>;
export type GetStockInvoicesQueryResult = Apollo.QueryResult<GetStockInvoicesQuery, GetStockInvoicesQueryVariables>;
export const GetStockInvoiceDocument = gql`
    mutation getStockInvoice($input: Float!) {
  getStockInvoice(input: $input) {
    bgInvoice {
      id
      stock_id
      date
      supplier
      category
      code
      color
      quantity
    }
    ogfInvoice {
      id
      stock_id
      date
      supplier
      category
      code
      color
      quantity
    }
    ogffInvoice {
      id
      stock_id
      date
      supplier
      category
      code
      color
      quantity
    }
    hcInvoice {
      id
      stock_id
      date
      supplier
      category
      code
      color
      quantity
    }
  }
}
    `;
export type GetStockInvoiceMutationFn = Apollo.MutationFunction<GetStockInvoiceMutation, GetStockInvoiceMutationVariables>;

/**
 * __useGetStockInvoiceMutation__
 *
 * To run a mutation, you first call `useGetStockInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetStockInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getStockInvoiceMutation, { data, loading, error }] = useGetStockInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStockInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<GetStockInvoiceMutation, GetStockInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetStockInvoiceMutation, GetStockInvoiceMutationVariables>(GetStockInvoiceDocument, options);
      }
export type GetStockInvoiceMutationHookResult = ReturnType<typeof useGetStockInvoiceMutation>;
export type GetStockInvoiceMutationResult = Apollo.MutationResult<GetStockInvoiceMutation>;
export type GetStockInvoiceMutationOptions = Apollo.BaseMutationOptions<GetStockInvoiceMutation, GetStockInvoiceMutationVariables>;
export const UpdateStockDocument = gql`
    mutation updateStock($input: UpdateStockInput!) {
  updateStock(input: $input)
}
    `;
export type UpdateStockMutationFn = Apollo.MutationFunction<UpdateStockMutation, UpdateStockMutationVariables>;

/**
 * __useUpdateStockMutation__
 *
 * To run a mutation, you first call `useUpdateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockMutation, { data, loading, error }] = useUpdateStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStockMutation, UpdateStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStockMutation, UpdateStockMutationVariables>(UpdateStockDocument, options);
      }
export type UpdateStockMutationHookResult = ReturnType<typeof useUpdateStockMutation>;
export type UpdateStockMutationResult = Apollo.MutationResult<UpdateStockMutation>;
export type UpdateStockMutationOptions = Apollo.BaseMutationOptions<UpdateStockMutation, UpdateStockMutationVariables>;
export const AdjustStockDocument = gql`
    mutation adjustStock($input: AdjustStockInput!) {
  adjustStock(input: $input)
}
    `;
export type AdjustStockMutationFn = Apollo.MutationFunction<AdjustStockMutation, AdjustStockMutationVariables>;

/**
 * __useAdjustStockMutation__
 *
 * To run a mutation, you first call `useAdjustStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdjustStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adjustStockMutation, { data, loading, error }] = useAdjustStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdjustStockMutation(baseOptions?: Apollo.MutationHookOptions<AdjustStockMutation, AdjustStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdjustStockMutation, AdjustStockMutationVariables>(AdjustStockDocument, options);
      }
export type AdjustStockMutationHookResult = ReturnType<typeof useAdjustStockMutation>;
export type AdjustStockMutationResult = Apollo.MutationResult<AdjustStockMutation>;
export type AdjustStockMutationOptions = Apollo.BaseMutationOptions<AdjustStockMutation, AdjustStockMutationVariables>;
export const GetUpdateStockQuantityDocument = gql`
    query getUpdateStockQuantity($input: UpdateStockQuantityInput!) {
  getUpdateStockQuantity(input: $input) {
    hasMore
    stock {
      id
      name
      brand
      category
      code
      quantity
      pv_id
      spv_id
      user {
        id
        username
        firstName
      }
      supplier
      date
    }
  }
}
    `;

/**
 * __useGetUpdateStockQuantityQuery__
 *
 * To run a query within a React component, call `useGetUpdateStockQuantityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateStockQuantityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateStockQuantityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUpdateStockQuantityQuery(baseOptions: Apollo.QueryHookOptions<GetUpdateStockQuantityQuery, GetUpdateStockQuantityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdateStockQuantityQuery, GetUpdateStockQuantityQueryVariables>(GetUpdateStockQuantityDocument, options);
      }
export function useGetUpdateStockQuantityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdateStockQuantityQuery, GetUpdateStockQuantityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdateStockQuantityQuery, GetUpdateStockQuantityQueryVariables>(GetUpdateStockQuantityDocument, options);
        }
export type GetUpdateStockQuantityQueryHookResult = ReturnType<typeof useGetUpdateStockQuantityQuery>;
export type GetUpdateStockQuantityLazyQueryHookResult = ReturnType<typeof useGetUpdateStockQuantityLazyQuery>;
export type GetUpdateStockQuantityQueryResult = Apollo.QueryResult<GetUpdateStockQuantityQuery, GetUpdateStockQuantityQueryVariables>;
export const GetSupplierListDocument = gql`
    query getSupplierList {
  getSupplierList {
    id
    name
    address
    creditPeriod
    supplierAdvance {
      id
      amount
    }
    supplierContacts {
      id
      contact
    }
  }
}
    `;

/**
 * __useGetSupplierListQuery__
 *
 * To run a query within a React component, call `useGetSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupplierListQuery(baseOptions?: Apollo.QueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, options);
      }
export function useGetSupplierListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, options);
        }
export type GetSupplierListQueryHookResult = ReturnType<typeof useGetSupplierListQuery>;
export type GetSupplierListLazyQueryHookResult = ReturnType<typeof useGetSupplierListLazyQuery>;
export type GetSupplierListQueryResult = Apollo.QueryResult<GetSupplierListQuery, GetSupplierListQueryVariables>;
export const GetPaymentTypesDocument = gql`
    query getPaymentTypes {
  getPaymentTypes {
    id
    name
  }
}
    `;

/**
 * __useGetPaymentTypesQuery__
 *
 * To run a query within a React component, call `useGetPaymentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentTypesQuery, GetPaymentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentTypesQuery, GetPaymentTypesQueryVariables>(GetPaymentTypesDocument, options);
      }
export function useGetPaymentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentTypesQuery, GetPaymentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentTypesQuery, GetPaymentTypesQueryVariables>(GetPaymentTypesDocument, options);
        }
export type GetPaymentTypesQueryHookResult = ReturnType<typeof useGetPaymentTypesQuery>;
export type GetPaymentTypesLazyQueryHookResult = ReturnType<typeof useGetPaymentTypesLazyQuery>;
export type GetPaymentTypesQueryResult = Apollo.QueryResult<GetPaymentTypesQuery, GetPaymentTypesQueryVariables>;
export const GetBanksDocument = gql`
    query getBanks {
  getBanks {
    id
    name
  }
}
    `;

/**
 * __useGetBanksQuery__
 *
 * To run a query within a React component, call `useGetBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBanksQuery(baseOptions?: Apollo.QueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
      }
export function useGetBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
        }
export type GetBanksQueryHookResult = ReturnType<typeof useGetBanksQuery>;
export type GetBanksLazyQueryHookResult = ReturnType<typeof useGetBanksLazyQuery>;
export type GetBanksQueryResult = Apollo.QueryResult<GetBanksQuery, GetBanksQueryVariables>;
export const GetOutstandingDocument = gql`
    query getOutstanding {
  getOutstanding {
    id
    name
    address
    outstanding
    last_settled_date
    days
  }
}
    `;

/**
 * __useGetOutstandingQuery__
 *
 * To run a query within a React component, call `useGetOutstandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOutstandingQuery(baseOptions?: Apollo.QueryHookOptions<GetOutstandingQuery, GetOutstandingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutstandingQuery, GetOutstandingQueryVariables>(GetOutstandingDocument, options);
      }
export function useGetOutstandingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutstandingQuery, GetOutstandingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutstandingQuery, GetOutstandingQueryVariables>(GetOutstandingDocument, options);
        }
export type GetOutstandingQueryHookResult = ReturnType<typeof useGetOutstandingQuery>;
export type GetOutstandingLazyQueryHookResult = ReturnType<typeof useGetOutstandingLazyQuery>;
export type GetOutstandingQueryResult = Apollo.QueryResult<GetOutstandingQuery, GetOutstandingQueryVariables>;
export const SupplierPaymentHistoryDocument = gql`
    query SupplierPaymentHistory($input: PaymentHistoryInput!) {
  paymentHistory(input: $input) {
    id
    date
    amount
    stockPaymentType {
      name
    }
    supplier {
      id
      name
    }
    paymentInfo {
      id
      cheque_number
      cheque_date
      bank {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSupplierPaymentHistoryQuery__
 *
 * To run a query within a React component, call `useSupplierPaymentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPaymentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPaymentHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierPaymentHistoryQuery(baseOptions: Apollo.QueryHookOptions<SupplierPaymentHistoryQuery, SupplierPaymentHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierPaymentHistoryQuery, SupplierPaymentHistoryQueryVariables>(SupplierPaymentHistoryDocument, options);
      }
export function useSupplierPaymentHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierPaymentHistoryQuery, SupplierPaymentHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierPaymentHistoryQuery, SupplierPaymentHistoryQueryVariables>(SupplierPaymentHistoryDocument, options);
        }
export type SupplierPaymentHistoryQueryHookResult = ReturnType<typeof useSupplierPaymentHistoryQuery>;
export type SupplierPaymentHistoryLazyQueryHookResult = ReturnType<typeof useSupplierPaymentHistoryLazyQuery>;
export type SupplierPaymentHistoryQueryResult = Apollo.QueryResult<SupplierPaymentHistoryQuery, SupplierPaymentHistoryQueryVariables>;
export const ChequePaymentsDocument = gql`
    query chequePayments($input: ChequePaymentInput!) {
  chequePayments(input: $input) {
    chequePayments {
      id
      supplierId
      amount
      paymentDate
      chequeDate
      status
    }
    hasMore
    totalAmount
  }
}
    `;

/**
 * __useChequePaymentsQuery__
 *
 * To run a query within a React component, call `useChequePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChequePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChequePaymentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChequePaymentsQuery(baseOptions: Apollo.QueryHookOptions<ChequePaymentsQuery, ChequePaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChequePaymentsQuery, ChequePaymentsQueryVariables>(ChequePaymentsDocument, options);
      }
export function useChequePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChequePaymentsQuery, ChequePaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChequePaymentsQuery, ChequePaymentsQueryVariables>(ChequePaymentsDocument, options);
        }
export type ChequePaymentsQueryHookResult = ReturnType<typeof useChequePaymentsQuery>;
export type ChequePaymentsLazyQueryHookResult = ReturnType<typeof useChequePaymentsLazyQuery>;
export type ChequePaymentsQueryResult = Apollo.QueryResult<ChequePaymentsQuery, ChequePaymentsQueryVariables>;
export const CreateSupplierDocument = gql`
    mutation createSupplier($input: CreateSupplierInput!) {
  createSupplier(input: $input) {
    supplier {
      id
      name
      address
      creditPeriod
      supplierContacts {
        id
        contact
      }
    }
    errors {
      message
    }
  }
}
    `;
export type CreateSupplierMutationFn = Apollo.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, options);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const PaymentDocument = gql`
    mutation payment($input: PaymentInput!) {
  payment(input: $input) {
    message
    errors {
      message
    }
  }
}
    `;
export type PaymentMutationFn = Apollo.MutationFunction<PaymentMutation, PaymentMutationVariables>;

/**
 * __usePaymentMutation__
 *
 * To run a mutation, you first call `usePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentMutation, { data, loading, error }] = usePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentMutation(baseOptions?: Apollo.MutationHookOptions<PaymentMutation, PaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentMutation, PaymentMutationVariables>(PaymentDocument, options);
      }
export type PaymentMutationHookResult = ReturnType<typeof usePaymentMutation>;
export type PaymentMutationResult = Apollo.MutationResult<PaymentMutation>;
export type PaymentMutationOptions = Apollo.BaseMutationOptions<PaymentMutation, PaymentMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation updateSupplier($input: UpdateSupplierInput!) {
  updateSupplier(input: $input) {
    supplier {
      id
      name
      address
      creditPeriod
      supplierContacts {
        id
        contact
      }
    }
    errors {
      message
    }
  }
}
    `;
export type UpdateSupplierMutationFn = Apollo.MutationFunction<UpdateSupplierMutation, UpdateSupplierMutationVariables>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, options);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = Apollo.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const DeleteSupplierDocument = gql`
    mutation deleteSupplier($id: Float!) {
  deleteSupplier(id: $id) {
    supplier {
      id
    }
    errors {
      message
    }
  }
}
    `;
export type DeleteSupplierMutationFn = Apollo.MutationFunction<DeleteSupplierMutation, DeleteSupplierMutationVariables>;

/**
 * __useDeleteSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierMutation, { data, loading, error }] = useDeleteSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSupplierMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSupplierMutation, DeleteSupplierMutationVariables>(DeleteSupplierDocument, options);
      }
export type DeleteSupplierMutationHookResult = ReturnType<typeof useDeleteSupplierMutation>;
export type DeleteSupplierMutationResult = Apollo.MutationResult<DeleteSupplierMutation>;
export type DeleteSupplierMutationOptions = Apollo.BaseMutationOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>;
export const StockSettlementDocument = gql`
    query stockSettlement($id: Float!) {
  stockSettlement(id: $id) {
    id
    stock_id
    stock_date
    creditPeriod
    supplier_id
    branch_id
    payable
    paid
    payment_date
    is_settled
    settled_date
  }
}
    `;

/**
 * __useStockSettlementQuery__
 *
 * To run a query within a React component, call `useStockSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockSettlementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockSettlementQuery(baseOptions: Apollo.QueryHookOptions<StockSettlementQuery, StockSettlementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StockSettlementQuery, StockSettlementQueryVariables>(StockSettlementDocument, options);
      }
export function useStockSettlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockSettlementQuery, StockSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StockSettlementQuery, StockSettlementQueryVariables>(StockSettlementDocument, options);
        }
export type StockSettlementQueryHookResult = ReturnType<typeof useStockSettlementQuery>;
export type StockSettlementLazyQueryHookResult = ReturnType<typeof useStockSettlementLazyQuery>;
export type StockSettlementQueryResult = Apollo.QueryResult<StockSettlementQuery, StockSettlementQueryVariables>;
export const SupplierReturnDocument = gql`
    mutation supplierReturn($input: [SupplierReturnInput!]!) {
  supplierReturn(input: $input)
}
    `;
export type SupplierReturnMutationFn = Apollo.MutationFunction<SupplierReturnMutation, SupplierReturnMutationVariables>;

/**
 * __useSupplierReturnMutation__
 *
 * To run a mutation, you first call `useSupplierReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierReturnMutation, { data, loading, error }] = useSupplierReturnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierReturnMutation(baseOptions?: Apollo.MutationHookOptions<SupplierReturnMutation, SupplierReturnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SupplierReturnMutation, SupplierReturnMutationVariables>(SupplierReturnDocument, options);
      }
export type SupplierReturnMutationHookResult = ReturnType<typeof useSupplierReturnMutation>;
export type SupplierReturnMutationResult = Apollo.MutationResult<SupplierReturnMutation>;
export type SupplierReturnMutationOptions = Apollo.BaseMutationOptions<SupplierReturnMutation, SupplierReturnMutationVariables>;
export const UpdateChequeStatusDocument = gql`
    mutation updateChequeStatus($input: ChequeUpdateStatusInput!) {
  updateChequeStatus(input: $input) {
    id
    branchId
    supplierId
    amount
    paymentDate
    chequeDate
    status
  }
}
    `;
export type UpdateChequeStatusMutationFn = Apollo.MutationFunction<UpdateChequeStatusMutation, UpdateChequeStatusMutationVariables>;

/**
 * __useUpdateChequeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateChequeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChequeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChequeStatusMutation, { data, loading, error }] = useUpdateChequeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChequeStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChequeStatusMutation, UpdateChequeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChequeStatusMutation, UpdateChequeStatusMutationVariables>(UpdateChequeStatusDocument, options);
      }
export type UpdateChequeStatusMutationHookResult = ReturnType<typeof useUpdateChequeStatusMutation>;
export type UpdateChequeStatusMutationResult = Apollo.MutationResult<UpdateChequeStatusMutation>;
export type UpdateChequeStatusMutationOptions = Apollo.BaseMutationOptions<UpdateChequeStatusMutation, UpdateChequeStatusMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    user {
      id
      username
      firstName
      lastName
      approved
      branch {
        id
        name
      }
      created_at
      role {
        id
        name
      }
    }
    errors {
      message
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: Float!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    user {
      id
      username
      firstName
      lastName
      approved
      branch {
        id
        name
      }
      created_at
      role {
        id
        name
      }
    }
    errors {
      message
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  getUsers {
    id
    username
    firstName
    lastName
    approved
    branch {
      id
      name
    }
    created_at
    role {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const SendPingDocument = gql`
    query sendPing {
  sendPing
}
    `;

/**
 * __useSendPingQuery__
 *
 * To run a query within a React component, call `useSendPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useSendPingQuery(baseOptions?: Apollo.QueryHookOptions<SendPingQuery, SendPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendPingQuery, SendPingQueryVariables>(SendPingDocument, options);
      }
export function useSendPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendPingQuery, SendPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendPingQuery, SendPingQueryVariables>(SendPingDocument, options);
        }
export type SendPingQueryHookResult = ReturnType<typeof useSendPingQuery>;
export type SendPingLazyQueryHookResult = ReturnType<typeof useSendPingLazyQuery>;
export type SendPingQueryResult = Apollo.QueryResult<SendPingQuery, SendPingQueryVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: Float!) {
  deleteUser(id: $id) {
    id
    errors {
      message
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;